import classNames from "classnames"
import domtoimage from "dom-to-image"
import { saveAs } from "file-saver"
import { CSSProperties, useState } from "react"
import { Button, Dropdown } from "react-bootstrap"
import { ReactComponent as Download } from "../../../assets/icons/download.svg"
import { ReactComponent as LegendX } from "../../../assets/icons/legend-x.svg"
import { ReactComponent as LegendY } from "../../../assets/icons/legend-y.svg"
import { ReactComponent as MatrixXAxes } from "../../../assets/icons/matrix-xaxes-48px.svg"
import { ReactComponent as MatrixYAxes } from "../../../assets/icons/matrix-yaxes-48px.svg"
import { ReactComponent as QuadrantsAll } from "../../../assets/icons/quadrants-all.svg"
import { ReactComponent as QuadrantsFirst } from "../../../assets/icons/quadrants-first.svg"
import { ReactComponent as QuadrantsFourth } from "../../../assets/icons/quadrants-fourth.svg"
import { ReactComponent as QuadrantsSecond } from "../../../assets/icons/quadrants-second.svg"
import { ReactComponent as QuadrantsThird } from "../../../assets/icons/quadrants-third.svg"
import { ReactComponent as TargetAll } from "../../../assets/icons/target-all.svg"
import { ReactComponent as TargetClosest } from "../../../assets/icons/target-closest.svg"
import { ReactComponent as TargetMid } from "../../../assets/icons/target-mid.svg"
import { useChartPoints } from "../../../hooks/chartPoints"
import {
  FiltersQueryTypes,
  FiltersType,
  QuadrantsType,
  TargetRangeType,
} from "../type"
import styles from "./ChartPage.module.css"
import { ScatterChart } from "./ScatterChart/ScatterChart"

type ChartPageProps = {
  filters: FiltersType
  filtersQuery: FiltersQueryTypes
  setFiltersQuery: (filter: FiltersQueryTypes) => void
  blockFilters: boolean
  setOpenBlockFilters: (b: boolean) => void
}
export function ChartPage({
  filters,
  filtersQuery,
  setFiltersQuery,
  blockFilters,
  setOpenBlockFilters,
}: ChartPageProps) {
  const { data } = useChartPoints(filtersQuery)
  const [quadrants, setQuadrants] = useState<QuadrantsType>("All")
  const [targetRange, setTargetRange] = useState<TargetRangeType>("All")
  const rt =
    filtersQuery.rt__lte !== undefined && filtersQuery.rt__gte !== undefined
      ? {
          value:
            (filtersQuery?.rt__lte - filtersQuery?.rt__gte) / 2 +
            filtersQuery?.rt__gte,
          tollerance: (filtersQuery?.rt__lte - filtersQuery?.rt__gte) / 2,
        }
      : { value: 0, tollerance: 0 }

  const mz =
    filters.masses === "mz" &&
    filtersQuery.mz__lte !== undefined &&
    filtersQuery.mz__gte !== undefined
      ? {
          value:
            (filtersQuery?.mz__lte - filtersQuery?.mz__gte) / 2 +
            filtersQuery?.mz__gte,
          tollerance: (filtersQuery?.mz__lte - filtersQuery?.mz__gte) / 2,
        }
      : { value: 0, tollerance: 0 }

  return (
    <div className={styles.ContentPage}>
      {!data && (
        <NoData
          filtersQuery={filtersQuery}
          mz={mz}
          rt={rt}
          setFiltersQuery={setFiltersQuery}
          blockFilters={blockFilters}
          setOpenBlockFilters={setOpenBlockFilters}
        />
      )}
      <FilterSection
        mz={mz}
        rt={rt}
        dataLen={data?.length}
        quadrants={quadrants}
        setQuadrants={setQuadrants}
        targetRange={targetRange}
        setTargetRange={setTargetRange}
      />
      {rt !== undefined && mz !== undefined && data && (
        <ScatterChart
          data={data}
          rt={rt}
          mz={mz}
          quadrants={quadrants}
          targetRange={targetRange}
        />
      )}
    </div>
  )
}

type NoDataProps = {
  rt?: { value?: number; tollerance?: number }
  mz?: { value?: number; tollerance?: number }
  filtersQuery: FiltersQueryTypes
  setFiltersQuery: (filter: FiltersQueryTypes) => void
  blockFilters: boolean
  setOpenBlockFilters: (b: boolean) => void
}
const NoData = ({
  rt,
  mz,
  filtersQuery,
  setFiltersQuery,
  blockFilters,
  setOpenBlockFilters,
}: NoDataProps) => {
  return (
    <div className={styles.NoDataBackground}>
      <div className={styles.NoDataBox}>
        <div className="d-flex flex-column align-items-center">
          <h3 className="title-h3 mb-3 text-primary">
            Set RT and m/z to display your chart
          </h3>
          <div className="paragraph-md text-center">
            Use the <span className="paragraph-md-bold">Advanced Filters </span>
            to input both RT and m/z values. <br />
            This is required to generate the chart
          </div>
          <div className={styles.NoDataAxes}>
            <TargetAxes
              value={mz?.value}
              tollerance={mz?.tollerance}
              type="y"
              text="m/z (ppm)"
            />
            <TargetAxes
              value={rt?.value}
              tollerance={rt?.tollerance}
              type="x"
              text="RT (min)"
            />
          </div>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <Button
              variant="outline-secondary"
              onClick={() =>
                setFiltersQuery({
                  ...filtersQuery,
                  view: "table" as "table" | "chart",
                })
              }
            >
              View the table
            </Button>
            <Button
              variant="secondary text-white"
              onClick={() => setOpenBlockFilters(!blockFilters)}
            >
              Go to advanced filters
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

type TargetAxesProps = {
  value?: number
  tollerance?: number
  type: "x" | "y"
  text: string
}

const TargetAxes = ({ value, tollerance, type, text }: TargetAxesProps) => {
  return (
    <div className="d-flex align-items-center gap-3">
      {type === "x" && <MatrixXAxes />}
      {type === "y" && <MatrixYAxes />}
      <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
        <div className="paragraph-sm-bold">{text}</div>
        <div className="paragraph-sm">{value || "---"}</div>
        <div className="paragraph-sm">
          {tollerance ? `± ${parseFloat(tollerance.toFixed(4))}` : "± ---"}
        </div>
      </div>
    </div>
  )
}

type FilterSectionProps = {
  rt?: { value?: number; tollerance?: number }
  mz?: { value?: number; tollerance?: number }
  dataLen?: number
  quadrants: QuadrantsType
  setQuadrants: (q: QuadrantsType) => void
  targetRange: TargetRangeType
  setTargetRange: (t: TargetRangeType) => void
}
const FilterSection = ({
  rt,
  mz,
  dataLen,
  quadrants,
  setQuadrants,
  targetRange,
  setTargetRange,
}: FilterSectionProps) => {
  const [ppmStr, setPpmStr] = useState<string>("")
  console.log(ppmStr)
  const download = () => {
    const svgNode = document.getElementById("scatterChart")
    if (!svgNode) return

    domtoimage.toPng(svgNode).then((dataUrl) => {
      saveAs(dataUrl, "chart.png")
    })
  }
  let ppmValue = null
  try {
    ppmValue = parseFloat(ppmStr)
    if (isNaN(ppmValue)) {
      ppmValue = null
    }
  } catch (e) {
    ppmValue = null
  }
  return (
    <div className={styles.FilterSection}>
      <div className={styles.TopFilter}>
        {/* SELECTORS */}
        <div className="d-flex align-items-center justify-content-start w-100 gap-4">
          <div className="d-flex flex-column align-items-start gap-2">
            <div className="paragraph-sm-bold">Quadrants</div>
            <Dropdown className={styles.Dropdown}>
              <Dropdown.Toggle
                className={styles.DropdownToggle}
                variant="outline"
                id="dropdown-quadrants"
                data-test="dropdown-quadrants"
              >
                {<Quadrants type={quadrants} />}
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.DropdownMenu}>
                {["All", "First", "Second", "Third", "Fourth"].map((item) => {
                  const quadrantItem = item as
                    | "All"
                    | "First"
                    | "Second"
                    | "Third"
                    | "Fourth"
                  return (
                    <Dropdown.Item
                      key={item}
                      className={classNames(`${styles.DropdownItem} mb-2`, {
                        "text-decoration-underline": item === quadrants,
                      })}
                      onClick={() => setQuadrants(quadrantItem)}
                      data-test="dropdown-item"
                    >
                      <Quadrants type={quadrantItem} />
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="d-flex flex-column align-items-start gap-2">
            <div className="paragraph-sm-bold">Target ranges</div>
            <Dropdown className={styles.Dropdown}>
              <Dropdown.Toggle
                className={styles.DropdownToggle}
                variant="outline"
                id="dropdown-target"
                data-test="dropdown-target"
              >
                {
                  <TargetRanges
                    type={targetRange}
                    style={{
                      width: 85,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                }
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.DropdownMenu}>
                {["All", "Mid", "Closest"].map((item) => {
                  const targetItem = item as "All" | "Mid" | "Closest"
                  return (
                    <Dropdown.Item
                      key={item}
                      className={classNames(`${styles.DropdownItem} mb-2`, {
                        "text-decoration-underline": item === targetRange,
                      })}
                      onClick={() => setTargetRange(targetItem)}
                      data-test="dropdown-item"
                    >
                      <TargetRanges type={targetItem} />
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/* AXES */}
        <div className="d-flex flex-column align-items-start gap-2 w-100">
          <div className="paragraph-sm-bold">Axes</div>
          <div className="d-flex align-items-center gap-2 w-100">
            <div
              className="d-flex align-items-center gap-2"
              style={{ width: 66 }}
            >
              <LegendY />
              <div className="paragraph-sm-bold">Δ m/z</div>
            </div>
            <div className="paragraph-md">=</div>
            <div className="d-flex flex-column gap-1 align-items-center">
              <div className="paragraph-md">(result m/z - target m/z)</div>
              <div
                style={{
                  width: "100%",
                  height: 1,
                  background: "var(--black-15)",
                }}
              />
              <div className="paragraph-md">target m/z</div>
            </div>
            <div className="paragraph-md pt-1">*</div>
            <div className="paragraph-md">10⁶</div>
          </div>
          <div className="d-flex align-items-center justify-content-between w-100 gap-2">
            <div className={styles.Ppm}>
              <span
                className={classNames(
                  "text-button-md-semibold text-primary",
                  styles.PpmUnit
                )}
              >
                {ppmStr || "XXX.XX"} Ppm
              </span>
              <input
                type="text"
                value={ppmStr}
                onChange={(e) => {
                  const val = e.target.value
                  if (val === "" || /^\d*\.?\d*$/.test(val)) {
                    setPpmStr(val)
                  }
                }}
              />
            </div>
            <div className="paragraph-md">=</div>
            <div className={styles.PpmDa}>
              {(ppmValue === null || !mz?.value) && (
                <div className="paragraph-sm-bold">XXXX</div>
              )}
              {ppmValue !== null && !!mz?.value && (
                <div className="paragraph-sm-bold">
                  {(mz.value / ((1 / ppmValue) * 1000000)).toFixed(4)}
                </div>
              )}
              <div className="paragraph-sm">Da</div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 w-100">
            <div
              className="d-flex align-items-center gap-2"
              style={{ width: 66 }}
            >
              <LegendX />
              <div className="paragraph-sm-bold">Δ RT</div>
            </div>
            <div className="paragraph-md">=</div>
            <div className="paragraph-md">target RT - result RT</div>
          </div>
        </div>

        {/* TARGET */}
        <div className="d-flex flex-column align-items-start gap-2">
          <div>
            <div className="paragraph-sm-bold mb-1">Target</div>
            <div className="paragraph-sm">
              Change taarget by editing advanced filters
            </div>
          </div>
          <TargetAxes
            value={mz?.value}
            tollerance={mz?.tollerance}
            type="y"
            text="m/z (ppm)"
          />
          <TargetAxes
            value={rt?.value}
            tollerance={rt?.tollerance}
            type="x"
            text="RT (min)"
          />
        </div>
      </div>
      {/* RESULTS */}
      <div className="d-flex align-items-center justify-content-between text-primary w-100">
        <div className="d-flex align-items-center gap-1">
          <div className="paragraph-sm-bold">{dataLen}</div>
          <div className="paragraph-sm">results</div>
        </div>
        <div
          className="d-flex align-items-center gap-2 pointer"
          onClick={download}
        >
          <div className={styles.DownloadImage}>Download image</div>
          <Download />
        </div>
      </div>
    </div>
  )
}

const Quadrants = ({
  type,
}: {
  type: "All" | "First" | "Second" | "Third" | "Fourth"
}) => {
  return (
    <div>
      {type === "All" && (
        <div className="d-flex align-items-center gap-1">
          <QuadrantsAll />
          <div className="text-button-md">All</div>
        </div>
      )}
      {type === "First" && (
        <div className="d-flex align-items-center gap-1">
          <QuadrantsFirst />
          <div className="text-button-md">First</div>
        </div>
      )}
      {type === "Second" && (
        <div className="d-flex align-items-center gap-1">
          <QuadrantsSecond />
          <div className="text-button-md">Second</div>
        </div>
      )}
      {type === "Third" && (
        <div className="d-flex align-items-center gap-1">
          <QuadrantsThird />
          <div className="text-button-md">Third</div>
        </div>
      )}
      {type === "Fourth" && (
        <div className="d-flex align-items-center gap-1">
          <QuadrantsFourth />
          <div className="text-button-md">Fourth</div>
        </div>
      )}
    </div>
  )
}

const TargetRanges = ({
  type,
  style,
}: {
  type: "All" | "Mid" | "Closest"
  style?: CSSProperties
}) => {
  return (
    <div>
      {type === "All" && (
        <div className="d-flex align-items-center gap-1" style={style}>
          <TargetAll style={{ flexShrink: 0 }} />
          <div
            className="text-button-md"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            All
          </div>
        </div>
      )}
      {type === "Mid" && (
        <div className="d-flex align-items-center gap-1" style={style}>
          <TargetMid style={{ flexShrink: 0 }} />
          <div
            className="text-button-md"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Mid & Closest
          </div>
        </div>
      )}
      {type === "Closest" && (
        <div className="d-flex align-items-center gap-1" style={style}>
          <TargetClosest style={{ flexShrink: 0 }} />
          <div
            className="text-button-md"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Closest
          </div>
        </div>
      )}
    </div>
  )
}
