import { QuadrantsType } from "../../type"

type QuadrantProps = {
  quadrant: QuadrantsType
  x: number
  y: number
  size: number
}

export function QuadrantGrid({
  steps,
  size,
  ...quadranProps
}: QuadrantProps & { steps: number[] }) {
  return (
    <>
      {steps.map((step) => (
        <Quadrant key={step} {...quadranProps} size={step * size} />
      ))}
    </>
  )
}

const Quadrant = ({ quadrant, x, y, size }: QuadrantProps) => {
  let gridLine, gridLine2
  switch (quadrant) {
    case "First":
      gridLine = <GridLine x1={x} x2={x + size} y1={y - size} y2={y - size} />
      gridLine2 = <GridLine x1={x + size} x2={x + size} y1={y - size} y2={y} />
      break
    case "Second":
      gridLine = <GridLine x1={x} x2={x - size} y1={y - size} y2={y - size} />
      gridLine2 = <GridLine x1={x - size} x2={x - size} y1={y - size} y2={y} />
      break
    case "Third":
      gridLine = <GridLine x1={x} x2={x - size} y1={y + size} y2={y + size} />
      gridLine2 = <GridLine x1={x - size} x2={x - size} y1={y} y2={y + size} />
      break
    case "Fourth":
      gridLine = <GridLine x1={x} x2={x + size} y1={y + size} y2={y + size} />
      gridLine2 = <GridLine x1={x + size} x2={x + size} y1={y} y2={y + size} />
      break
    case "All":
      gridLine = (
        <rect
          x={x - size / 2}
          y={y - size / 2}
          width={size}
          height={size}
          fill="transparent"
          stroke="var(--primary)"
          strokeWidth="1"
          strokeDasharray="3,3"
          opacity={0.4}
        />
      )
      gridLine2 = null
  }
  return (
    <>
      {gridLine}
      {gridLine2}
    </>
  )
}

type GridLineProps = {
  x1: number
  x2: number
  y1: number
  y2: number
}
const GridLine = ({ x1, x2, y1, y2 }: GridLineProps) => {
  return (
    <line
      x1={x1}
      x2={x2}
      y1={y1}
      y2={y2}
      stroke="var(--primary)"
      strokeWidth="1"
      strokeLinecap="round"
      strokeDasharray="3,3"
      opacity={0.4}
    />
  )
}
