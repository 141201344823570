import dayjs from "dayjs"
import styles from "../UserStatistics.module.css"
import { UserStatisticsType } from "../../../types"

type BannerProps = {
  data?: UserStatisticsType
}

export function Banner({ data }: BannerProps) {
  return (
    <div className={styles.banner}>
      <div className="d-flex justify-content-between align-items-center align-self-stretch">
        <h2 className="title-h2 text-white">User statistics</h2>
        <div className="d-flex py-2 align-items-start gap-1">
          <div className="paragraph-sm text-white">Count from date</div>
          <div className="paragraph-sm-bold text-white">
            {dayjs(data?.total.from_date).format("DD/MM/YYYY")}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-start gap-2 align-self-stretch justify-content-end w-100">
        <div className={styles.flatStat}>
          <table>
            <tr>
              <td className="pb-2">
                <h3 className="title-h3 text-white">
                  {data?.total.queries.toLocaleString("en-EN")}
                </h3>
              </td>
              <td className="pb-2 ps-3">
                <div className="paragraph-md text-white">Total queries</div>
              </td>
            </tr>
            <tr>
              <td className="pb-2">
                <h3 className="title-h3 text-white">
                  {data?.total.active_users}
                </h3>
              </td>
              <td className="pb-2 ps-3">
                <div className="paragraph-md text-white">Active users</div>
              </td>
            </tr>
            <tr>
              <td>
                <h3 className="title-h3 text-white">
                  {data?.total.hits.toLocaleString("en-EN")}
                </h3>
              </td>
              <td className="ps-3">
                <div className="paragraph-md text-white">
                  Queries with at least 1 hit
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="d-flex aling-items-start gap-2 align-self-stretch w-75 justify-content-between">
          <div className={styles.boxStat}>
            <h1 className="title-h1 text-primary">
              {data?.total.queries_over_time.toFixed()}
            </h1>
            <div className="paragraph-md-bold text-black-15">
              Average queries over time
            </div>
            <div className="paragraph-sm text-black-15">
              Total queries / Active users
            </div>
          </div>
          <div className={styles.boxStat}>
            <h1 className="title-h1 text-secondary">{`${(
              (data?.total?.hit_ratio ?? 0) * 100
            ).toFixed(1)} %`}</h1>
            <div className="paragraph-md-bold text-black-15">Hit ratio</div>
            <div className="paragraph-sm text-black-15 ">
              Queries with at least 1 hit/Total queries x 100
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
