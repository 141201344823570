import { OverlayTrigger } from "react-bootstrap"
import styles from "./CopyTooltip.module.css"
import { ReactComponent as Copy } from "../../assets/icons/copy.svg"
import { ReactComponent as Check } from "../../assets/icons/check-multiselect.svg"
import { useCallback, useEffect, useState } from "react"

type CopyTooltipProps = {
  text: string
  placement: "bottom-start" | "bottom-end"
  children: React.ReactElement
}

export const CopyTooltip = ({
  text,
  placement,
  children,
}: CopyTooltipProps) => {
  const [isHover, setIsHover] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const handleMouseEnter = useCallback(() => {
    setIsHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHover(false)
  }, [])

  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(text)
    setIsCopied(true)
  }, [text])

  useEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [isCopied])

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <div className={styles.TooltipBox} id="tooltip">
          <div className="paragraph-sm-semibold">{text}</div>
          <div className="paragraph-sm">
            {!isCopied ? "Click to copy" : "Copied"}
          </div>
        </div>
      }
    >
      <div
        className={styles.TextOverlay}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {children}
        {isHover && !isCopied && <Copy className={styles.copyIcon} />}
        {isHover && isCopied && (
          <Check height={14} width={14} style={{ flexShrink: 0 }} />
        )}
      </div>
    </OverlayTrigger>
  )
}

export default CopyTooltip
