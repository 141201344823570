import classNames from "classnames"
import dayjs from "dayjs"
import Select from "react-select"
import { UserStatisticsType } from "../../../types"
import { ReactComponent as CheckIcon } from "../../../assets/icons/checkbox-check.svg"
import { ReactComponent as UncheckIcon } from "../../../assets/icons/checkbox-uncheck.svg"
import styles from "../UserStatistics.module.css"

type FiltersProps = {
  data?: UserStatisticsType
  filters: { from_date: string; to_date: string }
  showAverage: boolean
  showHitRatio: boolean
  setFilters: (
    nextFilters: Partial<{
      from_date: string
      to_date: string
    }>
  ) => void
  setShowAverage: (a: boolean) => void
  setShowHitRatio: (a: boolean) => void
}

export function Filters({
  data,
  filters,
  setFilters,
  showAverage,
  showHitRatio,
  setShowAverage,
  setShowHitRatio,
}: FiltersProps) {
  const fromDate = filters.from_date || data?.query?.from_date
  const toDate = filters.to_date || data?.query?.to_date
  const yearsOptions = Array.from(
    { length: dayjs().year() - dayjs(fromDate).year() + 1 },
    (_, i) => {
      return {
        label: (dayjs(fromDate).year() + i).toString(),
        value: dayjs(fromDate).year() + i,
      }
    }
  )
  return (
    <div className={styles.filterSection}>
      <div className="paragraph-sm">Are you looking for more details?</div>
      <div className="d-flex flex-column align-items-start gap-2">
        <div className="paragraph-sm-bold text-black-15">
          Choose a time range
        </div>
        <div className="d-flex flex-column align-items-start gap-3">
          <div className="d-flex align-items-center gap-2">
            <div className={classNames(styles.rangeWidth, "paragraph-md")}>
              From
            </div>
            <Select
              classNamePrefix={"metadata-filter"}
              isSearchable={false}
              value={{
                value: dayjs(fromDate).month(),
                label: dayjs(fromDate).format("MMMM"),
              }}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  from_date: e
                    ? dayjs(fromDate).month(e.value).format("YYYY-MM-DD")
                    : "",
                })
              }}
              options={dayjs
                .months()
                .map((item, i) => ({ label: item, value: i }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: 140,
                }),
              }}
            />
            <Select
              classNamePrefix={"metadata-filter"}
              isSearchable={false}
              value={{
                value: dayjs(fromDate).year(),
                label: dayjs(fromDate).format("YYYY"),
              }}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  from_date: e
                    ? dayjs(fromDate).year(e.value).format("YYYY-MM-DD")
                    : "",
                })
              }}
              options={yearsOptions}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: 80,
                }),
              }}
            />
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className={classNames(styles.rangeWidth, "paragraph-md")}>
              to
            </div>
            <Select
              classNamePrefix={"metadata-filter"}
              isSearchable={false}
              value={{
                value: dayjs(toDate).month(),
                label: dayjs(toDate).format("MMMM"),
              }}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  to_date: e
                    ? dayjs(toDate).month(e.value).format("YYYY-MM-DD")
                    : "",
                })
              }}
              options={dayjs
                .months()
                .map((item, i) => ({ label: item, value: i }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: 140,
                }),
              }}
            />
            <Select
              classNamePrefix={"metadata-filter"}
              isSearchable={false}
              value={{
                value: dayjs(toDate).year(),
                label: dayjs(toDate).format("YYYY"),
              }}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  to_date: e
                    ? dayjs(toDate).year(e.value).format("YYYY-MM-DD")
                    : "",
                })
              }}
              options={yearsOptions}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  width: 80,
                }),
              }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-start gap-2">
        <div className="paragraph-sm-bold text-black-15">
          Select Data to be displayed
        </div>
        <div className="d-flex flex-column align-items-start gap-2">
          <div
            className="d-flex align-items-center gap-1 pointer"
            onClick={() => setShowAverage(!showAverage)}
          >
            {showAverage ? <CheckIcon /> : <UncheckIcon />}
            <div className="text-button-md text-black-15">
              Average queries over time
            </div>
          </div>
          <div
            className="d-flex align-items-center gap-1 pointer"
            onClick={() => setShowHitRatio(!showHitRatio)}
          >
            {showHitRatio ? <CheckIcon /> : <UncheckIcon />}
            <div className="text-button-md text-black-15">
              Hit ratio over time
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
