import dayjs from "dayjs"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Customized,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { UserStatisticsType } from "../../../types"
import styles from "../UserStatistics.module.css"

type HitRatioChartProps = {
  data?: UserStatisticsType
}

export default function HitRatioChart({ data }: HitRatioChartProps) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data?.period}
        margin={{ top: 12, bottom: -5 }}
      >
        <Customized component={CustomizeGrid} />
        <CartesianGrid
          vertical={false}
          horizontal={true}
          stroke="#151515"
          opacity={0.2}
          strokeDasharray="2 2"
        />

        <YAxis
          axisLine={false}
          tickLine={false}
          orientation="right"
          tickMargin={10}
          tick={CustomYAxisTick}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Bar
          dataKey="hit_ratio"
          barSize={12}
          fill="var(--secondary)"
          background={{
            fill: "rgba(248, 164, 100, 0.20)",
            stroke: "var(--secondary)",
            strokeWidth: 1,
          }}
        />
        <XAxis
          tick={CustomXAxisTick}
          dataKey="month"
          stroke="#151515"
          axisLine={{ stroke: "#c0c0c0", height: 3 }}
          tickLine={false}
          interval={0}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

const CustomizeGrid = (props: any) => {
  return (
    <>
      <line
        x1={0}
        x2={0}
        y1={props.margin.top}
        y2={props.offset.height + props.margin.top}
        stroke="#F4F4F4"
        strokeWidth={2}
      />
      {props?.data?.map((_: any, i: number, list: UserStatisticsType[]) => {
        return (
          <line
            x1={(props.offset.width / list.length) * (i + 1)}
            x2={(props.offset.width / list.length) * (i + 1)}
            y1={props.margin.top}
            y2={props.offset.height + props.margin.top}
            stroke="#F4F4F4"
            strokeWidth={1}
          />
        )
      })}
    </>
  )
}
const CustomXAxisTick = ({ x, y, payload }: any) => {
  return (
    <foreignObject width={60} height={15} x={x - 60 / 2} y={y + 2}>
      <div
        className="paragraph-sm text-black-15 text-center"
        style={{ opacity: 0.6 }}
      >
        {dayjs(payload.value).format("MMM, YYYY")}
      </div>
    </foreignObject>
  )
}
const CustomYAxisTick = ({ x, y, payload }: any) => {
  return (
    <foreignObject width={70} height={38} x={x} y={y - 8}>
      <div
        className="paragraph-sm-bold text-black-15 text-start"
        style={{ opacity: 0.6, fontWeight: 700 }}
      >
        {payload.value === 0 ? payload.value : `${payload.value * 100}%`}
      </div>
    </foreignObject>
  )
}

const CustomTooltip = ({ active, payload, coordinate, ...props }: any) => {
  if (active && payload && payload.length) {
    const { x } = coordinate || {}
    return (
      <div
        className={styles.tooltipHitRatio}
        style={{
          position: "absolute",
          left: x - 22.5,
          top: (props.viewBox.height - props.offset) / 2,
        }}
      >
        <div className="paragraph-sm-bold text-black-15">
          {`${(payload[0].value * 100).toFixed(0)}%`}
        </div>
      </div>
    )
  }

  return null
}
