import { QuadrantsType } from "../../type"

type LabelsGridProps = {
  steps: number[]
  size: number
  quadrant: QuadrantsType
  x: number
  y: number
  domain: {
    xOuterStart: number
    xOuterEnd: number
    yOuterTop: number
    yOuterBottom: number
  }
}
export function LabelsGrid({ steps, size, ...labelsProps }: LabelsGridProps) {
  return steps.map((step, i) => (
    <Labels
      key={step}
      size={step * size}
      origin={i === 0}
      last={i === steps.length - 1}
      step={step}
      {...labelsProps}
    />
  ))
}

type LabelsProps = {
  quadrant: QuadrantsType
  x: number
  y: number
  size: number
  origin?: boolean
  last?: boolean
  domain: {
    xOuterStart: number
    xOuterEnd: number
    yOuterTop: number
    yOuterBottom: number
  }
  step: number
}

const Labels = ({
  x: xEntry,
  y: yEntry,
  size,
  quadrant,
  origin,
  last,
  domain,
  step,
}: LabelsProps) => {
  let xValue, yValue
  let xLabel = { x: xEntry, y: yEntry, value: "", align: "text-center" }
  let yLabel = { x: xEntry, y: yEntry, value: "", align: "text-center" }
  let xLabelAxes = { x: xEntry, y: yEntry, value: "RT", align: "text-center" }
  let yLabelAxes = {
    x: xEntry,
    y: yEntry,
    value: "m/z (ppm)",
    align: "text-center",
  }
  switch (quadrant) {
    case "First":
      xValue = (
        domain.xOuterStart +
        (domain.xOuterEnd - domain.xOuterStart) * step
      ).toFixed(1)
      yValue = (
        domain.yOuterBottom +
        (domain.yOuterTop - domain.yOuterBottom) * step
      ).toFixed(1)
      xLabel = {
        x: xEntry + size,
        y: yEntry + 6,
        value: xValue,
        align: "text-start",
      }
      xLabelAxes = {
        ...xLabelAxes,
        x: xEntry + size,
        y: yEntry + 22,
        align: "text-start",
      }
      yLabel = {
        x: xEntry - yValue.length * 8 - 8,
        y: yEntry - size - 7 * 2,
        value: yValue,
        align: "text-end",
      }
      yLabelAxes = {
        ...yLabelAxes,
        x: xEntry - yValue.length * 8 - 8,
        y: yEntry - size - 7 * 4,
        align: "text-start",
      }
      break
    case "Second":
      xValue = (
        domain.xOuterEnd +
        (domain.xOuterStart - domain.xOuterEnd) * step
      ).toFixed(1)
      yValue = (
        domain.yOuterBottom +
        (domain.yOuterTop - domain.yOuterBottom) * step
      ).toFixed(1)
      xLabel = {
        x: xEntry - size - yValue.length - 8,
        y: yEntry + 6,
        value: xValue,
        align: "text-start",
      }
      xLabelAxes = {
        ...xLabelAxes,
        x: xEntry - size - yValue.length - 8,
        y: yEntry + 22,
        align: "text-start",
      }
      yLabel = {
        x: xEntry + yValue.length + 6,
        y: yEntry - size - 7 * 2,
        value: yValue,
        align: "text-start",
      }
      yLabelAxes = {
        ...yLabelAxes,
        x: xEntry - yValue.length,
        y: yEntry - size - 7 * 4,
        align: "text-end",
      }
      break
    case "Third":
      xValue = (
        domain.xOuterEnd +
        (domain.xOuterStart - domain.xOuterEnd) * step
      ).toFixed(1)
      yValue = (
        domain.yOuterTop +
        (domain.yOuterBottom - domain.yOuterTop) * step
      ).toFixed(1)
      xLabel = {
        x: xEntry - size - yValue.length - 8,
        y: yEntry - 20,
        value: xValue,
        align: "text-start",
      }
      xLabelAxes = {
        ...xLabelAxes,
        x: xEntry - size - yValue.length - 8,
        y: yEntry - 34,
        align: "text-start",
      }
      yLabel = {
        x: xEntry + yValue.length + 6,
        y: yEntry + size,
        value: yValue,
        align: "text-start",
      }
      yLabelAxes = {
        ...yLabelAxes,
        x: xEntry - yValue.length,
        y: yEntry + size + 14,
        align: "text-end",
      }
      break
    case "Fourth":
      xValue = (
        domain.xOuterStart +
        (domain.xOuterEnd - domain.xOuterStart) * step
      ).toFixed(1)
      yValue = (
        domain.yOuterTop +
        (domain.yOuterBottom - domain.yOuterTop) * step
      ).toFixed(1)
      xLabel = {
        x: xEntry + size,
        y: yEntry - 20,
        value: xValue,
        align: "text-start",
      }
      xLabelAxes = {
        ...xLabelAxes,
        x: xEntry + size,
        y: yEntry - 34,
        align: "text-start",
      }
      yLabel = {
        x: xEntry - yValue.length * 8 - 8,
        y: yEntry + size,
        value: yValue,
        align: "text-end",
      }
      yLabelAxes = {
        ...yLabelAxes,
        x: xEntry - yValue.length * 8,
        y: yEntry + size + 14,
        align: "text-end",
      }
      break
    case "All":
      const { yOuterTop, yOuterBottom, xOuterStart, xOuterEnd } = domain
      return (
        <>
          <foreignObject
            y={yEntry - size / 2 - 48}
            x={xEntry - (yLabelAxes.value.length * 8) / 2}
            width={yLabelAxes.value.length * 8}
            height={14}
          >
            <div className="paragraph-sm-bold text-center">
              {yLabelAxes.value}
            </div>
          </foreignObject>
          <foreignObject
            y={yEntry - size / 2 - 30}
            x={xEntry - yOuterTop.toFixed(1).length * (8 / 2)}
            width={yOuterTop.toFixed(1).length * 8}
            height={14}
          >
            <div className="paragraph-sm text-center">
              {yOuterTop.toFixed(1)}
            </div>
          </foreignObject>
          <foreignObject
            y={yEntry + size / 2 + 10}
            x={xEntry - yOuterBottom.toFixed(1).length * (8 / 2)}
            width={yOuterBottom.toFixed(1).length * 8}
            height={14}
          >
            <div className="paragraph-sm text-center">
              {yOuterBottom.toFixed(1)}
            </div>
          </foreignObject>
          <foreignObject
            y={yEntry - 8}
            x={xEntry - size / 2 - xOuterStart.toFixed(1).length * 8 - 16}
            width={xOuterStart.toFixed(1).length * 8}
            height={14}
          >
            <div className="paragraph-sm text-end">
              {xOuterStart.toFixed(1)}
            </div>
          </foreignObject>
          <foreignObject
            y={yEntry - 8}
            x={xEntry + size / 2 + 16}
            width={xOuterEnd.toFixed(1).length * 8}
            height={14}
          >
            <div className="paragraph-sm text-start">
              {xOuterEnd.toFixed(1)}
            </div>
          </foreignObject>
          <foreignObject
            y={yEntry - 25}
            x={xEntry + size / 2 + 16}
            width={xLabelAxes.value.length * 8}
            height={14}
          >
            <div className="paragraph-sm-bold text-start">
              {xLabelAxes.value}
            </div>
          </foreignObject>
        </>
      )
  }
  return (
    <>
      {last && (
        <foreignObject
          y={xLabelAxes.y}
          x={xLabelAxes.x}
          width={xLabelAxes.value.length * 8}
          height={14}
        >
          <div className={`paragraph-sm-bold ${xLabelAxes.align}`}>
            {xLabelAxes.value}
          </div>
        </foreignObject>
      )}
      <foreignObject
        y={xLabel.y}
        x={xLabel.x}
        width={xLabel.value.length * 8}
        height={14}
      >
        <div className={`paragraph-sm${origin ? "-bold" : ""} ${xLabel.align}`}>
          {xLabel.value}
        </div>
      </foreignObject>
      <foreignObject
        y={yLabel.y}
        x={yLabel.x}
        width={yLabel.value.length * 8}
        height={14}
      >
        <div className={`paragraph-sm${origin ? "-bold" : ""} ${yLabel.align}`}>
          {yLabel.value}
        </div>
      </foreignObject>
      {last && (
        <foreignObject
          y={yLabelAxes.y}
          x={yLabelAxes.x}
          width={yLabelAxes.value.length * 8}
          height={14}
        >
          <div className={`paragraph-sm-bold ${yLabelAxes.align}`}>
            {yLabelAxes.value}
          </div>
        </foreignObject>
      )}
    </>
  )
}
