import { Formik } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useEffect, useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import * as Yup from "yup"
import { ReactComponent as Close } from "../../../assets/icons/close-upload.svg"
import { ReactComponent as SafeAlert } from "../../../assets/icons/safe-alert.svg"
import { ReactComponent as TimeDuration } from "../../../assets/icons/time-duration.svg"
import { ReactComponent as Unlink } from "../../../assets/icons/unlink.svg"
import { useFeedbacks } from "../../../hooks/feedback"
import { Compound, FeedbackKind, FeedbackPayload } from "../../../types"
import ConfirmModal from "./ConfirmModal"
import styles from "./TablePage.module.css"
import classNames from "classnames"
import { useAuthUser } from "use-eazy-auth"

interface CommentsModalProps {
  show: boolean
  toggle: () => void | undefined
  compound: Compound
  save: (id: number, feedback: FeedbackPayload) => Promise<unknown>
  onClosed: () => void
}

const FeedbackSchema = Yup.object().shape({
  comment: Yup.string(),
})

const initialValues = {
  comment: "",
}

export default function CommentsModal({
  show,
  toggle,
  compound,
  save,
  onClosed,
}: CommentsModalProps) {
  const { data: feedbacks } = useFeedbacks(compound.id.toString())
  const { user } = useAuthUser()
  const [visibility, setVisibility] = useState<"visible" | "hidden">("visible")
  const [modalConfirm, modalConfirmActions] = useModalTrigger()
  const [shadowTop, setShadowTop] = useState(false)
  const [shadowBottom, setShadowBottom] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)

  const areFeedbacksLoaded = feedbacks !== undefined

  useEffect(() => {
    if (areFeedbacksLoaded) {
      const elem = scrollRef.current as HTMLDivElement
      const scrollHeight = elem.scrollHeight
      const height = elem.clientHeight
      if (height !== scrollHeight) {
        setShadowTop(true)
      } else {
        setShadowTop(false)
      }
      setShadowBottom(false)
    }
  }, [areFeedbacksLoaded])

  const confirmSave = async (id: number, comment: string) => {
    try {
      return await save(id, {
        comment,
        kind: compound.user_feedback?.kind ?? "ambiguous_data",
      })
    } catch (err) {}
  }

  return (
    <Modal
      centered
      show={show}
      onHide={toggle}
      data-test="modal-comments"
      onExited={onClosed}
      style={{ visibility: visibility }}
    >
      <Formik
        validationSchema={FeedbackSchema}
        onSubmit={(values) => {
          if (
            feedbacks?.find(
              (item) => item.user_email === compound.user_feedback?.user_email
            )?.comment
          ) {
            setVisibility("hidden")
            modalConfirmActions.open(values.comment)
          } else {
            confirmSave(compound.id, values.comment)
          }
        }}
        validateOnMount
        initialValues={initialValues}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body
              className={"ModalBody pt-2"}
              style={{
                paddingTop: 34,
                paddingBottom: 34,
                paddingLeft: 87,
                paddingRight: 87,
              }}
            >
              <div
                className="d-flex justify-content-end pointer"
                onClick={toggle}
                style={{ marginRight: -80 }}
              >
                <Close
                  className={"text-primary"}
                  style={{ width: 24, height: 24, margin: 4 }}
                />
              </div>
              <h3 className={styles.TitleModal}>Comments from the community</h3>
              <div className={classNames(styles.TextModal, "mb-3")}>
                {"Subject: "}
                <span className={"paragraph-md-bold mb-3"}>
                  {compound.identification}
                </span>
              </div>

              <div className="position-relative">
                {feedbacks && (
                  <div
                    className="mb-2 d-flex flex-column-reverse gap-1"
                    style={{ overflow: "auto", maxHeight: 350 }}
                    ref={scrollRef}
                    onScroll={(e) => {
                      const elem = e.target as HTMLDivElement
                      const scrollTop = elem.scrollTop
                      const scrollHeight = elem.scrollHeight
                      const height = elem.clientHeight
                      if (scrollTop !== 0) {
                        setShadowBottom(true)
                      } else {
                        setShadowBottom(false)
                      }
                      if (scrollTop !== height - scrollHeight) {
                        setShadowTop(true)
                      } else {
                        setShadowTop(false)
                      }
                      if (scrollTop === 0 && height === scrollHeight) {
                        setShadowBottom(false)
                        setShadowTop(false)
                      }
                    }}
                  >
                    {[...feedbacks].reverse()?.map((feedback) => {
                      const isYou = feedback.user_email === user.email
                      return (
                        <div className={styles.FeedbackBox}>
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className={classNames(
                                styles.TextEmail,
                                `paragraph-md-bold ${
                                  isYou ? "text-secondary" : "text-primary"
                                }`
                              )}
                            >
                              {isYou ? "You" : feedback.user_email}
                            </div>
                            <FeedbackChip
                              kind={feedback.kind}
                              deleted={feedback.deleted}
                            />
                          </div>
                          {feedback.comment && (
                            <div className={styles.FeedbackComment}>
                              {feedback.comment}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                )}
                {shadowTop && <div className={styles.GradientTopShadow} />}
                {shadowBottom && (
                  <div className={styles.GradientBottomShadow} />
                )}
              </div>

              {compound.user_feedback?.kind && (
                <>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "rgba(177, 177, 177, 0.2)",
                      paddingBottom: 8,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingLeft: 12,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                    }}
                  >
                    <div className="paragraph-md-bold text-primary">You</div>
                    <FeedbackChip kind={compound.user_feedback.kind} />
                  </div>
                  <div className="pb-2">
                    <textarea
                      placeholder="Type here.. (max. 100 characters)"
                      value={values.comment}
                      className={styles.TextAreaField}
                      onChange={(e) => {
                        setFieldValue("comment", e.target.value)
                      }}
                      rows={2}
                      maxLength={100}
                    />
                    <div
                      className="d-flex justify-content-end"
                      style={{
                        backgroundColor: "rgba(177, 177, 177, 0.2)",
                        paddingBottom: 8,
                        paddingTop: 8,
                        paddingRight: 12,
                        borderBottomLeftRadius: 4,
                        borderBottomRightRadius: 4,
                      }}
                    >
                      <Button
                        type="submit"
                        size="sm"
                        variant="secondary text-white rounded-pill px-3"
                        disabled={!values.comment}
                      >
                        Add Comment
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </form>
        )}
      </Formik>
      {modalConfirm.value && (
        <ConfirmModal
          show={modalConfirm.isOpen}
          toggle={() => {
            modalConfirmActions.toggle()
            onClosed()
          }}
          compound={compound}
          onClosed={() => {
            modalConfirmActions.onClosed()
            onClosed()
          }}
          onConfirm={() => {
            confirmSave(compound.id, modalConfirm.value)
            modalConfirmActions.close()
          }}
          comment={modalConfirm.value}
          lastFeedback={feedbacks?.find(
            (item) => item.user_email === compound.user_feedback?.user_email
          )}
        />
      )}
    </Modal>
  )
}

const FeedbackChip = ({
  kind,
  deleted,
}: {
  kind?: FeedbackKind
  deleted?: boolean
}) => {
  return (
    <>
      {kind === "mass_mismatch" && (
        <div
          className={
            !deleted
              ? styles.FeedbackChipRedFilled
              : styles.FeedbackChipGreyFilled
          }
          style={{ cursor: "default" }}
        >
          <Unlink />
          <div className="paragraph-sm">Mass mismatch</div>
        </div>
      )}
      {kind === "ambiguous_data" && (
        <div
          className={
            !deleted
              ? styles.FeedbackChipYellowFilled
              : styles.FeedbackChipGreyFilled
          }
          style={{ cursor: "default" }}
        >
          <SafeAlert />
          <div className="paragraph-sm">Ambigous data</div>
        </div>
      )}
      {kind === "uncertain_time" && (
        <div
          className={
            !deleted
              ? styles.FeedbackChipYellowFilled
              : styles.FeedbackChipGreyFilled
          }
          style={{ cursor: "default" }}
        >
          <TimeDuration />
          <div className="paragraph-sm">Uncertain time</div>
        </div>
      )}
    </>
  )
}
