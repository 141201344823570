import classNames from "classnames"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useMemo } from "react"
import { Dropdown, OverlayTrigger } from "react-bootstrap"
import { ReactComponent as RadioChecked } from "../../../assets/icons/radio-selected.svg"
import { ReactComponent as RadioUnchecked } from "../../../assets/icons/radio-unselected.svg"
import { ReactComponent as SafeAlert } from "../../../assets/icons/safe-alert.svg"
import { ReactComponent as TimeDuration } from "../../../assets/icons/time-duration.svg"
import { ReactComponent as Unlink } from "../../../assets/icons/unlink.svg"
import { ReactComponent as UpAndDown } from "../../../assets/icons/up-down.svg"
import { ReactComponent as Matching } from "../../../assets/matching.svg"
import { ReactComponent as NotFound } from "../../../assets/not-found.svg"
import { ReactComponent as Unmatching } from "../../../assets/unmatching.svg"
import MatchUnmatchMasses from "../../../components/DataManagement/MatchUnmatchMasses/MatchUnmatchMasses"
import Paginator from "../../../components/Paginator"
import Sorter, { SortControl } from "../../../components/Sorter"
import StickyTable from "../../../components/StickyTable"
import { PAGE_SIZE } from "../../../consts"
import { useRemoveFeedback, useSetFeedback } from "../../../hooks/feedback"
import { Compound, FeedbackKind, PaginatedDJResponse } from "../../../types"
import { amountFormatter, amountFormatterIt } from "../Home"
import stylesHome from "../Home.module.css"
import { FiltersType } from "../type"
import CommentsModal from "./CommentsModal"
import ConfirmModal from "./ConfirmModal"
import styles from "./TablePage.module.css"
import CopyTooltip from "../../../components/CopyTooltip/CopyTooltip"

type TablePageProps = {
  data?: PaginatedDJResponse<Compound>
  enableCompounds: boolean
  qsParams: FiltersType
  setQsParams: (f: FiltersType) => void
  filters: FiltersType
  setFilters: (f: FiltersType) => void
}

export default function TablePage({
  data,
  enableCompounds,
  qsParams,
  setQsParams,
  filters,
  setFilters,
}: TablePageProps) {
  const [modalComments, modalCommentsActions] = useModalTrigger()
  const [modalConfirm, modalConfirmActions] = useModalTrigger()

  const setFeedback = useSetFeedback()
  const removeFeedback = useRemoveFeedback()

  const onFeedbackClick = useCallback(
    (
      compound: Compound,
      feedback: { kind: FeedbackKind; comment?: string },
      lastFeedback?: FeedbackKind
    ) => {
      if (lastFeedback === feedback.kind) {
        modalConfirmActions.open({ compound: compound, feedback: feedback })
      } else {
        setFeedback.mutateAsync({
          id: compound.id,
          feedback: feedback,
        })
      }
      return
    },
    [modalConfirmActions, setFeedback]
  )

  const maxMasses = useMemo(() => {
    const masses = data?.results?.map((d) => d.masses?.length || 0) || []
    return Math.max(...masses) - 1
  }, [data])

  return (
    <>
      {qsParams.masses === "all" &&
        qsParams.mass_value &&
        data?.results &&
        data!.results.length > 0 && (
          <div className={styles.LegendMasses}>
            <div className="paragraph-sm">m/z</div>
            <div className="d-flex align-items-center paragraph-sm-bold">
              <Matching className="mx-1" /> Matching
            </div>
            <div className="d-flex align-items-center paragraph-sm-bold">
              <Unmatching className="mx-1" /> Unmatching
            </div>
          </div>
        )}
      {data?.results && data!.results.length > 0 && enableCompounds && (
        <StickyTable
          className={`${styles.TableHome} table-fixed flex-1 mt-3`}
          data-test="table"
        >
          <thead>
            <Sorter
              value={filters.ordering}
              onSortChange={(ordering) => {
                setFilters({
                  ...filters,
                  ordering,
                  page: 1,
                })
                setQsParams({
                  ...filters,
                  ordering,
                  page: 1,
                })
              }}
            >
              <tr>
                <th
                  style={{ width: "17%" }}
                  className="border-radius-left-20 align-middle "
                >
                  <Dropdown
                    className={styles.Dropdown}
                    data-test="dropdown-user"
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      style={{ lineHeight: 1 }}
                    >
                      <div className="d-flex align-items-center gap-1">
                        Feedback <UpAndDown opacity={0.5} cursor="pointer" />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={styles.DropdownMenu}>
                      <Dropdown.Header className={styles.DropdownHeader}>
                        Order
                      </Dropdown.Header>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({ ...qsParams, feed_order: "" })
                        }
                      >
                        {qsParams.feed_order === "" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_order === ""
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          Null
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({ ...qsParams, feed_order: "asc" })
                        }
                      >
                        {qsParams.feed_order === "asc" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_order === "asc"
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          Ascending
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({ ...qsParams, feed_order: "desc" })
                        }
                      >
                        {qsParams.feed_order === "desc" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_order === "desc"
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          Descending
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider className="m-0" />
                      <Dropdown.Header className={styles.DropdownHeader}>
                        Category
                      </Dropdown.Header>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({ ...qsParams, feed_cat: "" })
                        }
                      >
                        {qsParams.feed_cat === "" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_cat === ""
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          None
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({
                            ...qsParams,
                            feed_cat: "mass_mismatch",
                          })
                        }
                      >
                        {qsParams.feed_cat === "mass_mismatch" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_cat === "mass_mismatch"
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          Mass mismatch
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({
                            ...qsParams,
                            feed_cat: "ambiguous_data",
                          })
                        }
                      >
                        {qsParams.feed_cat === "ambiguous_data" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_cat === "ambiguous_data"
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          Ambigous data
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.DropdownItem}
                        onClick={() =>
                          setQsParams({
                            ...qsParams,
                            feed_cat: "uncertain_time",
                          })
                        }
                      >
                        {qsParams.feed_cat === "uncertain_time" ? (
                          <RadioChecked />
                        ) : (
                          <RadioUnchecked />
                        )}
                        <div
                          className={
                            qsParams.feed_cat === "uncertain_time"
                              ? "text-decoration-underline"
                              : ""
                          }
                        >
                          Uncertain time
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th style={{ width: "27%" }}>
                  <div className="d-flex">
                    ID Code <SortControl field="identification" />
                  </div>
                </th>
                <th style={{ width: "6%" }}>
                  <div className="d-flex">
                    RT <SortControl field="rt" />
                  </div>
                </th>
                <th style={{ width: "7%" }}>
                  <div className="d-flex">
                    m/z <SortControl field="mz" />
                  </div>
                </th>
                <th style={{ width: "32%" }}>Related m/z</th>
                {qsParams.metadata === "operator" && (
                  <th style={{ width: "8%" }}>Operators</th>
                )}
                {qsParams.metadata === "solution" && (
                  <th style={{ width: "8%" }}>Solution</th>
                )}
                {qsParams.metadata === "study" && (
                  <th style={{ width: "8%" }}>Study</th>
                )}
                <th style={{ width: "10%" }}>
                  <div className="d-flex">
                    CAS <SortControl field="cas" />
                  </div>
                </th>
                <th style={{ width: "11%" }} className="border-radius-right-20">
                  <div className="d-flex">
                    Note <SortControl field="note" />
                  </div>
                </th>
              </tr>
            </Sorter>
          </thead>
          <tbody>
            {data?.results?.map((compound) => {
              return (
                <tr key={compound.id}>
                  <td>
                    <div className="d-flex flex-column w-100 h-100 gap-1">
                      <div className="d-flex align-items-center w-100 gap-1">
                        {(
                          [
                            "mass_mismatch",
                            "ambiguous_data",
                            "uncertain_time",
                          ] as FeedbackKind[]
                        ).map((item) => {
                          return (
                            <FeedbackChip
                              key={item}
                              kind={item}
                              compound={compound}
                              onClick={(kind) =>
                                onFeedbackClick(
                                  compound,
                                  { kind },
                                  compound.user_feedback?.kind
                                )
                              }
                            />
                          )
                        })}
                      </div>
                      <div
                        className={`text-button-sm text-decoration-underline text-primary pointer`}
                        onClick={() => modalCommentsActions.open(compound)}
                      >
                        Read comment
                      </div>
                    </div>
                  </td>
                  <td>
                    <CopyTooltip text={compound.identification} placement="bottom-start">
                      <div
                        className={classNames(
                          styles.Identification,
                          "paragraph-sm-bold"
                        )}
                      >
                        {compound.identification}
                      </div>
                    </CopyTooltip>
                  </td>
                  <td>{amountFormatterIt.format(compound.rt)}</td>
                  <td>
                    <MatchUnmatchMasses
                      masses={"all"}
                      mz={Number(amountFormatter.format(compound.mz))}
                      mz_min={
                        Number(qsParams.mass_value) -
                        Number(qsParams.mass_tollerance)
                      }
                      mz_max={
                        Number(qsParams.mass_value) +
                        Number(qsParams.mass_tollerance)
                      }
                    />
                  </td>
                  <td className="text-nowrap">
                    <div className="d-flex flex-wrap">
                      {compound.masses?.map(
                        (mass, i) =>
                          i !== 0 && (
                            <div key={i}>
                              <MatchUnmatchMasses
                                masses={qsParams.masses}
                                mz={mass.mz}
                                mz_min={
                                  Number(qsParams.mass_value) -
                                  Number(qsParams.mass_tollerance)
                                }
                                mz_max={
                                  Number(qsParams.mass_value) +
                                  Number(qsParams.mass_tollerance)
                                }
                              />
                            </div>
                          )
                      )}
                      {(compound.masses?.length || 0) - 1 <= maxMasses &&
                        maxMasses !== -1 &&
                        [
                          ...Array(
                            maxMasses -
                              (compound.masses!.length !== 0
                                ? compound.masses!.length - 1
                                : 0)
                          ),
                        ].map((n, i) => (
                          <div className="placheholder-matching" key={i}>
                            {/* -*/}
                          </div>
                        ))}
                    </div>
                  </td>
                  {qsParams.metadata === "operator" && (
                    <td className="text-nowrap">{compound.study.operator}</td>
                  )}
                  {qsParams.metadata === "solution" && (
                    <td className="text-nowrap">{compound.study.solution}</td>
                  )}
                  {qsParams.metadata === "study" && (
                    <td className="text-nowrap">{compound.study.key}</td>
                  )}
                  <td title={compound.cas} className="text-nowrap">
                    <div className={styles.Cas}>
                      {compound.cas === "" ? "-" : compound.cas}
                    </div>
                  </td>
                  <td>
                    <CopyTooltip text={compound.note} placement="bottom-end">
                      <div
                        className={styles.Notes}
                        onClick={() => {
                          navigator.clipboard.writeText(compound.note)
                        }}
                      >
                        {compound.note === "" ? "-" : compound.note}
                      </div>
                    </CopyTooltip>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </StickyTable>
      )}
      {modalComments.value && (
        <CommentsModal
          show={modalComments.isOpen}
          toggle={modalCommentsActions.toggle}
          compound={modalComments.value}
          save={async (id, feedback) => {
            await setFeedback.mutateAsync({ id, feedback })
            modalCommentsActions.close()
          }}
          onClosed={modalCommentsActions.onClosed}
        />
      )}
      {modalConfirm.value && (
        <ConfirmModal
          show={modalConfirm.isOpen}
          toggle={modalConfirmActions.toggle}
          compound={modalConfirm.value.compound}
          onConfirm={async () => {
            await removeFeedback.mutateAsync({
              id: modalConfirm.value.compound.id,
            })
            modalConfirmActions.close()
          }}
          onClosed={modalConfirmActions.onClosed}
        />
      )}
      {((data?.results && data?.results.length === 0) || !data?.results) && (
        <div className="h-100 d-flex flex-column align-items-center justify-content-center">
          <NotFound />
          <div className={stylesHome.NotFound}>No results found</div>
          <div className={stylesHome.InteractPanel}>
            Interact with the filter panel to discover the data
          </div>
        </div>
      )}
      {data?.results && data!.results.length > 0 && (
        <div className={styles.Paginator}>
          <div className="d-flex align-items-center justify-content-start">
            <div className="paragraph-sm-bold text-primary">{`${
              filters.page * PAGE_SIZE - PAGE_SIZE + 1
            }-${Math.min(
              filters.page * PAGE_SIZE,
              data!.results.length
            )}`}</div>
            <div className="ms-1 paragraph-sm text-primary">{`of ${
              data!.count
            } results`}</div>
          </div>
          <Paginator
            // className="d-flex align-items-center justify-content-center"
            count={data!.count}
            currentPage={filters.page}
            goToPage={(page) => {
              setFilters({ ...filters, page })
              setQsParams({ ...filters, page })
            }}
          />
        </div>
      )}
    </>
  )
}

type FeedbackChipProps = {
  kind: FeedbackKind
  compound: Compound
  onClick: (kind: FeedbackKind) => void
}

const FeedbackChip = ({ kind, compound, onClick }: FeedbackChipProps) => {
  let style = styles.FeedbackChipYellow
  let styleFilled = styles.FeedbackChipYellowFilled
  let text = ""
  if (kind === "mass_mismatch") {
    style = styles.FeedbackChipRed
    styleFilled = styles.FeedbackChipRedFilled
    text = "Incorrect identification (m/z discrepancy)"
  } else if (kind === "ambiguous_data") {
    text = "The identification is valid, but a plausibile second option exists"
  } else if (kind === "uncertain_time") {
    text = "Unreliable or mismatcher retention time"
  }

  return (
    <OverlayTrigger
      key={kind}
      placement="bottom"
      overlay={
        <div id={`tooltip-${kind}`} className={styles.TooltipFeedback}>
          <div className={styleFilled} style={{ cursor: "default" }}>
            {kind === "mass_mismatch" && (
              <>
                <Unlink />
                <div className="paragraph-sm">Mass mismatch</div>
              </>
            )}
            {kind === "ambiguous_data" && (
              <>
                <SafeAlert />
                <div className="paragraph-sm">Ambigous data</div>
              </>
            )}
            {kind === "uncertain_time" && (
              <>
                <TimeDuration />
                <div className="paragraph-sm">Uncertain time</div>
              </>
            )}
          </div>
          {text}
        </div>
      }
    >
      <div
        className={kind !== compound.user_feedback?.kind ? style : styleFilled}
        style={{ cursor: "pointer" }}
        onClick={() => onClick(kind)}
      >
        {kind === "mass_mismatch" && <Unlink />}
        {kind === "ambiguous_data" && <SafeAlert />}
        {kind === "uncertain_time" && <TimeDuration />}
        <div className="paragraph-sm">{compound[`feedback_${kind}`]}</div>
      </div>
    </OverlayTrigger>
  )
}
