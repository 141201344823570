import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.scss"
import "./typography.css"
import "./utils.css"
import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"


dayjs.extend(localeData)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
