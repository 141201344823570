import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"
import Layout from "./components/Layout"
import NotFound from "./components/NotFound"
import DataProvider from "./DataProvider"
import ErrorBoundary from "./ErrorBoundary"
import Auth from "./lib/auth"
import { RequireAuth, RequireGuest } from "./lib/routing"
import AccountManagement from "./pages/AccountManagement/AccountManagement"
import { ActivateAccount } from "./pages/ActivateAccount"
import DataManagement from "./pages/DataManagement/DataManagement"
import { Home } from "./pages/Home"
import { Login } from "./pages/Login"
import { Profile } from "./pages/Profile"
import { ResetPassword } from "./pages/ResetPassword"
import UserStatistics from "./pages/UserStatistics/UserStatistics"

function App() {
  return (
    <ErrorBoundary>
      <DataProvider>
        <Auth>
          <BrowserRouter>
            {/* GUEST */}
            <Routes>
              <Route
                path="/"
                element={
                  <RequireGuest>
                    <Outlet />
                  </RequireGuest>
                }
              >
                <Route path="login" element={<Login />} />
                <Route path="reset/:token" element={<ResetPassword />} />
                <Route path="activate/:token" element={<ActivateAccount />} />
              </Route>
              {/* AUTH */}
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <Layout />
                  </RequireAuth>
                }
              >
                <Route index element={<Home />} />
                <Route path="user-statistics" element={<UserStatistics />} />
                <Route
                  path="account-management"
                  element={<AccountManagement />}
                />
                <Route path="data-management" element={<DataManagement />} />
                <Route path="profile" element={<Profile />} />
                <Route path="*" element={<NotFound />} />
              </Route>
              {/* <Route
            path="/"
            element={
              <RequireAuth redirectTest={checkUserIsStaff}>
                <Layout />
              </RequireAuth>
            }
          >
            <Route path="users/*" element={<Users />} />
          </Route> */}
            </Routes>
          </BrowserRouter>
        </Auth>
      </DataProvider>
    </ErrorBoundary>
  )
}

export default App
