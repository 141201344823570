import classNames from "classnames"
import { format } from "date-fns"
import dayjs from "dayjs"
import { useCallback, useMemo, useState } from "react"
import { Button, Dropdown, Form } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import { ReactComponent as CancelSearch } from "../../assets/icons/cancel-search.svg"
import { ReactComponent as CheckIcon } from "../../assets/icons/checkbox-check.svg"
import { ReactComponent as UncheckIcon } from "../../assets/icons/checkbox-uncheck.svg"
import { ReactComponent as Close } from "../../assets/icons/close-upload.svg"
import { ReactComponent as FiltersActive } from "../../assets/icons/filter-line-active.svg"
import { ReactComponent as Filters } from "../../assets/icons/filter-line.svg"
import { ReactComponent as Search } from "../../assets/icons/search-line.svg"
import { useCompounds } from "../../hooks/compounds"
import { useQsFilters } from "../../hooks/filters"
import { useOperators } from "../../hooks/operators"
import { useSolutions } from "../../hooks/solutions"
import { useTimespan } from "../../hooks/timespan"
import { CompoundSearchParams, FeedbackKind } from "../../types"
import { ChartPage } from "./ChartPage/ChartPage"
import styles from "./Home.module.css"
import TablePage from "./TablePage/TablePage"
import { FiltersType } from "./type"

export const amountFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 10,
})

export const amountFormatterIt = new Intl.NumberFormat("it-IT", {
  maximumFractionDigits: 10,
})

export const LimitedChipsContainer = ({ children, ...props }: any) => {
  if (props.index > 0) return null

  const { length } = props.getValue()
  return length > 0 && props.data.value !== "" ? (
    <>
      <span className={styles.FilterNumberSelect}>{`${length}`}</span>
      <span className={styles.ItemsLabel}>Items</span>
    </>
  ) : (
    <span className={styles.ItemsLabelPlaceholder}>
      {props.selectProps.placeholder}
    </span>
  )
}

const filtersDefault = {
  page: 1,
  type_search: "identification_search",
  study_cluster: "ESI+",
  search: "",
  rt: "",
  rt_tollerance: "",
  masses: "all",
  mass_value: "",
  mass_tollerance: "",
  mass1_value: "",
  mass1_tollerance: "",
  mass2_value: "",
  mass2_tollerance: "",
  mass3_value: "",
  mass3_tollerance: "",
  mass4_value: "",
  mass4_tollerance: "",
  study__date__lte: "",
  study__date__gte: "",
  study__key: "",
  metadata: "operator",
  has_cas: false,
  has_note: false,
  ordering: "-id",
  study__solution__in: [],
  study__operator__in: [],
  view: "chart" as "chart" | "table",
  feed_cat: "" as "" | FeedbackKind,
  feed_order: "" as "" | "asc" | "desc",
} as FiltersType

const optionsMetadata = [
  { value: "operator", label: "Operator" },
  { value: "solution", label: "Solution" },
  { value: "study", label: "Study" },
]

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? filtersDefault.page),
  search: params.get("search") ?? filtersDefault.search,
  type_search: params.get("type_search") ?? filtersDefault.type_search,
  study_cluster: params.get("study_cluster") ?? filtersDefault.study_cluster,
  rt: params.get("rt") ?? filtersDefault.rt,
  rt_tollerance: params.get("rt_tollerance") ?? filtersDefault.rt_tollerance,
  masses: params.get("masses") ?? filtersDefault.masses,
  mass_value: params.get("mass_value") ?? filtersDefault.mass_value,
  mass_tollerance:
    params.get("mass_tollerance") ?? filtersDefault.mass_tollerance,
  mass1_value: params.get("mass1_value") ?? filtersDefault.mass1_value,
  mass1_tollerance:
    params.get("mass1_tollerance") ?? filtersDefault.mass1_tollerance,
  mass2_value: params.get("mass2_value") ?? filtersDefault.mass2_value,
  mass2_tollerance:
    params.get("mass2_tollerance") ?? filtersDefault.mass2_tollerance,
  mass3_value: params.get("mass3_value") ?? filtersDefault.mass3_value,
  mass3_tollerance:
    params.get("mass3_tollerance") ?? filtersDefault.mass3_tollerance,
  mass4_value: params.get("mass4_value") ?? filtersDefault.mass4_value,
  mass4_tollerance:
    params.get("mass4_tollerance") ?? filtersDefault.mass4_tollerance,
  study__date__lte:
    params.get("study__date__lte") ?? filtersDefault.study__date__lte,
  study__date__gte:
    params.get("study__date__gte") ?? filtersDefault.study__date__gte,
  study__key: params.get("study__key") ?? filtersDefault.study__key,
  metadata: params.get("metadata") ?? filtersDefault.metadata,
  has_cas: (params.get("has_cas") ?? "false") === "true",
  has_note: (params.get("has_note") ?? "false") === "true",
  ordering: params.get("ordering") ?? filtersDefault.ordering,
  study__operator__in:
    params.getAll("study__operator__in") ?? filtersDefault.study__operator__in,
  study__solution__in:
    params.getAll("study__solution__in") ?? filtersDefault.study__solution__in,
  view: params.get("view") ?? filtersDefault.view,
  feed_cat: params.get("feed_cat") ?? filtersDefault.feed_cat,
  feed_order: params.get("feed_order") ?? filtersDefault.feed_order,
})

export function Home() {
  const { filters: qsParams, setFilters: setQsParams } =
    useQsFilters(initFilters)
  const [filters, _setFilters] = useState(qsParams)

  const isFiltersActive =
    Boolean(filters.mass_tollerance) ||
    Boolean(filters.mass_value) ||
    Boolean(filters.mass1_tollerance) ||
    Boolean(filters.mass1_value) ||
    Boolean(filters.mass2_tollerance) ||
    Boolean(filters.mass2_value) ||
    Boolean(filters.mass3_tollerance) ||
    Boolean(filters.mass3_value) ||
    Boolean(filters.mass4_tollerance) ||
    Boolean(filters.mass4_value) ||
    Boolean(filters.rt) ||
    Boolean(filters.rt_tollerance) ||
    Boolean(filters.study__date__gte) ||
    Boolean(filters.study__date__lte) ||
    Boolean(filters.metadata !== "operator") ||
    Boolean(filters.study__key) ||
    Boolean(filters.study_cluster !== "ESI+") ||
    Boolean(filters.study__operator__in.length > 0) ||
    Boolean(filters.study__solution__in.length > 0) ||
    Boolean(filters.has_cas === true) ||
    Boolean(filters.has_note === true)

  const [blockFilters, setOpenBlockFilters] = useState(!isFiltersActive)
  const [enableCompounds, setCompoundsEnabled] = useState(isFiltersActive)

  const setFilters = useCallback((updates: any) => {
    _setFilters((v) => ({ ...v, ...updates }))
  }, [])

  const apiFilters = useMemo(() => {
    const filters: Partial<CompoundSearchParams> = {
      page: qsParams.page,
      study_cluster: qsParams.study_cluster,
      study__operator__in: qsParams.study__operator__in,
      study__solution__in: qsParams.study__solution__in,
      study__date__gte: qsParams.study__date__gte,
      study__date__lte: qsParams.study__date__lte,
      study__key: qsParams.study__key,
      has_cas: qsParams.has_cas,
      has_note: qsParams.has_note,
      metadata: qsParams.metadata,
      ordering: qsParams.ordering,
      view: qsParams.view as "table" | "chart",
    }

    if (qsParams.rt) {
      const rtBase = parseFloat(qsParams.rt)
      const rtTolerance = parseFloat(qsParams.rt_tollerance || "0")
      filters.rt__gte = rtBase - rtTolerance
      filters.rt__lte = rtBase + rtTolerance
    }
    if (qsParams.masses === "all") {
      if (qsParams.mass_value) {
        const massBase = parseFloat(qsParams.mass_value)
        if (qsParams.mass_tollerance) {
          const massTolerance = parseFloat(qsParams.mass_tollerance)
          filters.any_mass_min = massBase - massTolerance
          filters.any_mass_max = massBase + massTolerance
        } else {
          filters.any_mass_min = massBase
          filters.any_mass_max = massBase
        }
      }
    }
    if (qsParams.masses === "mz") {
      if (qsParams.mass_value) {
        const massBase = parseFloat(qsParams.mass_value)
        if (qsParams.mass_tollerance) {
          const massTolerance = parseFloat(qsParams.mass_tollerance)
          filters.mz__gte = massBase - massTolerance
          filters.mz__lte = massBase + massTolerance
        } else {
          filters.mz__gte = massBase
          filters.mz__lte = massBase
        }
      }
      if (qsParams.mass1_value) {
        const massBase1 = parseFloat(qsParams.mass1_value)
        if (qsParams.mass1_tollerance) {
          const massTolerance1 = parseFloat(qsParams.mass1_tollerance)
          filters.mz1__gte = massBase1 - massTolerance1
          filters.mz1__lte = massBase1 + massTolerance1
        } else {
          filters.mz1__gte = massBase1
          filters.mz1__lte = massBase1
        }
      }
      if (qsParams.mass2_value) {
        const massBase2 = parseFloat(qsParams.mass2_value)
        if (qsParams.mass2_tollerance) {
          const massTolerance2 = parseFloat(qsParams.mass2_tollerance)
          filters.mz2__gte = massBase2 - massTolerance2
          filters.mz2__lte = massBase2 + massTolerance2
        } else {
          filters.mz2__gte = massBase2
          filters.mz2__lte = massBase2
        }
      }
      if (qsParams.mass3_value) {
        const massBase3 = parseFloat(qsParams.mass3_value)
        if (qsParams.mass3_tollerance) {
          const massTolerance3 = parseFloat(qsParams.mass3_tollerance)
          filters.mz3__gte = massBase3 - massTolerance3
          filters.mz3__lte = massBase3 + massTolerance3
        } else {
          filters.mz3__gte = massBase3
          filters.mz3__lte = massBase3
        }
      }
      if (qsParams.mass4_value) {
        const massBase4 = parseFloat(qsParams.mass4_value)
        if (qsParams.mass4_tollerance) {
          const massTolerance4 = parseFloat(qsParams.mass4_tollerance)
          filters.mz4__gte = massBase4 - massTolerance4
          filters.mz4__lte = massBase4 + massTolerance4
        } else {
          filters.mz4__gte = massBase4
          filters.mz4__lte = massBase4
        }
      }
    }
    if (qsParams.feed_cat !== "" && qsParams.feed_order !== "") {
      filters.ordering =
        (qsParams.feed_order === "asc" ? "" : "-") +
        `feedback_${qsParams.feed_cat}`
    } else {
      filters.ordering = qsParams.ordering
    }
    if (qsParams.search) {
      switch (qsParams.type_search) {
        case "identification_search":
        case "identification_lookup":
        case "identification_note_search":
        case "identification_note_lookup":
        case "identification_cas_lookup":
        case "identification_cas_search":
          filters[qsParams.type_search] = qsParams.search
          break
        default:
        // do nothing
      }
    }
    return filters
  }, [
    qsParams.page,
    qsParams.study_cluster,
    qsParams.study__operator__in,
    qsParams.study__solution__in,
    qsParams.study__date__gte,
    qsParams.study__date__lte,
    qsParams.study__key,
    qsParams.has_cas,
    qsParams.has_note,
    qsParams.metadata,
    qsParams.ordering,
    qsParams.view,
    qsParams.rt,
    qsParams.rt_tollerance,
    qsParams.mass_value,
    qsParams.mass_tollerance,
    qsParams.masses,
    qsParams.feed_cat,
    qsParams.feed_order,
    qsParams.search,
    qsParams.mass1_value,
    qsParams.mass2_value,
    qsParams.mass3_value,
    qsParams.mass4_value,
    qsParams.mass1_tollerance,
    qsParams.mass2_tollerance,
    qsParams.mass3_tollerance,
    qsParams.mass4_tollerance,
    qsParams.type_search,
  ])

  const isFiltersActiveApplied =
    Boolean(apiFilters.rt__lt) ||
    Boolean(apiFilters.rt__lte) ||
    Boolean(apiFilters.rt__gt) ||
    Boolean(apiFilters.rt__gte) ||
    Boolean(apiFilters.study_cluster !== "ESI+") ||
    Boolean(apiFilters.metadata !== "operator") ||
    Boolean(apiFilters.mz__lt) ||
    Boolean(apiFilters.mz__lte) ||
    Boolean(apiFilters.mz__gt) ||
    Boolean(apiFilters.mz__gte) ||
    Boolean(apiFilters.mz1__lt) ||
    Boolean(apiFilters.mz1__lte) ||
    Boolean(apiFilters.mz1__gt) ||
    Boolean(apiFilters.mz1__gte) ||
    Boolean(apiFilters.mz2__lt) ||
    Boolean(apiFilters.mz2__lte) ||
    Boolean(apiFilters.mz2__gt) ||
    Boolean(apiFilters.mz2__gte) ||
    Boolean(apiFilters.mz3__lt) ||
    Boolean(apiFilters.mz3__lte) ||
    Boolean(apiFilters.mz3__gt) ||
    Boolean(apiFilters.mz3__gte) ||
    Boolean(apiFilters.mz4__lt) ||
    Boolean(apiFilters.mz4__lte) ||
    Boolean(apiFilters.mz4__gt) ||
    Boolean(apiFilters.mz4__gte) ||
    Boolean(apiFilters.study__date__gte) ||
    Boolean(apiFilters.study__date__lte) ||
    Boolean(apiFilters.study__key) ||
    Boolean((apiFilters.study__operator__in?.length ?? 0) > 0) ||
    Boolean((apiFilters.study__solution__in?.length ?? 0) > 0) ||
    Boolean(apiFilters.has_cas === true) ||
    Boolean(apiFilters.has_note === true) ||
    Boolean(apiFilters.any_mass_min) ||
    Boolean(apiFilters.any_mass_max)

  const { data } = useCompounds(apiFilters, enableCompounds)
  const { data: operators } = useOperators()
  const { data: solutions } = useSolutions()
  const { data: timespan } = useTimespan()
  const defaultDate = useMemo(() => new Date(), [])

  const operatorsForSelect = useMemo(() => {
    if (operators) {
      const op = operators.map((op) => ({
        label: op,
        value: op,
      }))
      return [{ value: "", label: "All operators" }].concat(op)
    }
  }, [operators])

  const solutionsForSelect = useMemo(() => {
    if (solutions) {
      const solu = solutions.map((sol) => ({
        label: sol,
        value: sol,
      }))
      return [{ value: "", label: "All solutions" }].concat(solu)
    }
  }, [solutions])

  const dateLte = useMemo(() => {
    if (filters.study__date__lte) {
      return new Date(filters.study__date__lte)
    } else {
      return new Date(timespan?.max_date ?? defaultDate)
    }
  }, [defaultDate, filters.study__date__lte, timespan?.max_date])

  const dateGte = useMemo(() => {
    if (filters.study__date__gte) {
      return new Date(filters.study__date__gte)
    } else {
      return new Date(timespan?.min_date ?? defaultDate)
    }
  }, [defaultDate, filters.study__date__gte, timespan?.min_date])

  // TODO: Chiedere se presente ancora
  // const isDateFilterActive = useMemo(() => {
  //   return (
  //     (filters.study__date__gte &&
  //       filters.study__date__gte !== timespan?.min_date) ||
  //     (filters.study__date__lte &&
  //       filters.study__date__lte !== timespan?.max_date)
  //   )
  // }, [filters, timespan])

  return (
    <div className="w-100 h-100 mb-3" data-test="page-home">
      <div className={styles.Home}>
        <div className="w-100 d-flex flex-column align-items-center position-relative">
          <div className={styles.BoxSearch}>
            <div className={styles.SearchBar}>
              <Dropdown className={styles.NavLink}>
                <Dropdown.Toggle
                  className={styles.ActiveSearch}
                  variant="transparent"
                  id="dropdown-basic"
                  data-test="dropdown-identification_code"
                >
                  {filters.type_search === "identification_search" ||
                  filters.type_search === "identification_lookup"
                    ? "Identification Code"
                    : filters.type_search === "identification_note_search" ||
                      filters.type_search === "identification_note_lookup"
                    ? "Id. code and Note"
                    : "CAS"}
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.DropdownMenu}>
                  <Dropdown.Item
                    className={classNames(`${styles.DropdownItem} mb-2`, {
                      "text-decoration-underline":
                        filters.type_search === "identification_search" ||
                        filters.type_search === "identification_lookup",
                    })}
                    onClick={() => {
                      setFilters({
                        type_search: "identification_search",
                        page: 1,
                      })
                    }}
                    data-test="dropdown-item"
                  >
                    Identification Code
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={classNames(`${styles.DropdownItem} mb-2`, {
                      "text-decoration-underline":
                        filters.type_search === "identification_note_search" ||
                        filters.type_search === "identification_note_lookup",
                    })}
                    onClick={() => {
                      setFilters({
                        type_search: "identification_note_search",
                        page: 1,
                      })
                    }}
                  >
                    Id. code and Note
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={classNames(styles.DropdownItem, {
                      "text-decoration-underline":
                        filters.type_search === "identification_cas_search" ||
                        filters.type_search === "identification_cas_lookup",
                    })}
                    onClick={() => {
                      setFilters({
                        type_search: "identification_cas_search",
                        page: 1,
                      })
                    }}
                  >
                    CAS
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={styles.Divider}></div>
              <Search className={styles.SearchIcon} />
              <input
                placeholder="Search"
                className={
                  filters.search === ""
                    ? styles.SearchInput
                    : styles.SearchInputActive
                }
                type="text"
                value={filters.search}
                onChange={(e) => setFilters({ search: e.target.value })}
              />
              {filters.search !== "" && (
                <div
                  className={styles.CancelSearch}
                  onClick={() => {
                    setFilters({
                      search: "",
                    })
                    setQsParams({
                      ...filters,
                      search: "",
                    })
                  }}
                >
                  <CancelSearch />
                </div>
              )}
              <div className={styles.BlockSearchButton}>
                <Form.Check
                  checked={
                    filters.type_search === "identification_lookup" ||
                    filters.type_search === "identification_note_lookup" ||
                    filters.type_search === "identification_cas_lookup"
                  }
                  type="switch"
                  className={styles.MatchingResults}
                  onChange={() => {
                    if (filters.type_search === "identification_search") {
                      setFilters({
                        type_search: "identification_lookup",
                      })
                    } else if (
                      filters.type_search === "identification_note_search"
                    ) {
                      setFilters({
                        type_search: "identification_note_lookup",
                      })
                    } else if (
                      filters.type_search === "identification_note_lookup"
                    ) {
                      setFilters({
                        type_search: "identification_note_search",
                      })
                    } else if (
                      filters.type_search === "identification_lookup"
                    ) {
                      setFilters({
                        type_search: "identification_search",
                      })
                    } else if (
                      filters.type_search === "identification_cas_search"
                    ) {
                      setFilters({
                        type_search: "identification_cas_lookup",
                      })
                    } else if (
                      filters.type_search === "identification_cas_lookup"
                    ) {
                      setFilters({
                        type_search: "identification_cas_search",
                      })
                    }
                  }}
                  label={"Matching results"}
                  data-test="switch-matchs_results"
                />
                <button
                  type="submit"
                  onClick={() => {
                    setQsParams({ ...filters, page: 1 })
                    setOpenBlockFilters(false)
                    setCompoundsEnabled(true)
                  }}
                  className={`${styles.SearchButton} btn btn-secondary text-white`}
                >
                  Search
                </button>
              </div>
            </div>
            <Button
              className={classNames(
                styles.ButtonAdvancedFilters,
                "text-button-md-semibold rounded-pill"
              )}
              onClick={() => setOpenBlockFilters(!blockFilters)}
            >
              Advanced filters
              {blockFilters ? (
                <Close width={24} height={24} />
              ) : isFiltersActiveApplied ? (
                <FiltersActive />
              ) : (
                <Filters />
              )}
            </Button>
          </div>
          {/* FINE SEARCH */}
          {/* INIZIO FILTERS */}
          <div
            className={styles.BlockFilters}
            style={{
              opacity: !blockFilters ? "0" : "1",
              pointerEvents: !blockFilters ? "none" : "all",
            }}
          >
            <div
              className={styles.BlockFiltersContent}
              style={{ transform: !blockFilters ? "scaleY(0)" : "scaleY(1)" }}
            >
              <div className={styles.AdvancedFiltersTitle}>
                Advanced filters
              </div>
              <div className="d-flex row flex-nowrap">
                <div
                  className="d-flex flex-column gap-4"
                  style={{ flexGrow: 0, flexShrink: 1, flexBasis: 456 }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>Study cluster</div>
                      <div className={styles.ContainerSwitch}>
                        <div
                          onClick={() =>
                            setFilters({ ...filters, study_cluster: "ESI+" })
                          }
                          className={
                            filters.study_cluster === "ESI+"
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-cluster-1"
                        >
                          ESI+
                        </div>
                        <div
                          onClick={() =>
                            setFilters({ ...filters, study_cluster: "ESI-" })
                          }
                          className={
                            filters.study_cluster === "ESI-"
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-cluster-2"
                        >
                          ESI-
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>RT</div>
                      <div className="d-flex gap-2">
                        <input
                          className={styles.InputRT}
                          title="RT"
                          placeholder="000,000"
                          type={"number"}
                          onChange={(e) =>
                            setFilters({ ...filters, rt: e.target.value })
                          }
                          name="rt"
                          value={filters.rt}
                          data-test="input-rt1"
                        />
                        <div className={styles.TolleranceContainer}>
                          <input
                            className={classNames(
                              `${styles.InputRtTollerance}`,
                              { "ps-4": filters.rt_tollerance }
                            )}
                            title="RT"
                            placeholder={filters.rt_tollerance ? "" : "± 0,000"}
                            type={"number"}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                rt_tollerance: e.target.value,
                              })
                            }
                            name="rt_tollerance"
                            value={filters.rt_tollerance}
                            data-test="input-rt2"
                          />
                          {filters.rt_tollerance && (
                            <span className={styles.Unit}>± </span>
                          )}
                        </div>
                      </div>
                      <div className="paragraph-sm-bold opacity-50">
                        Plotted data on x-axis: from{" "}
                        <span className="paragraph-sm">
                          {filters.rt && filters.rt_tollerance
                            ? +(
                                Number(filters.rt) -
                                Number(filters.rt_tollerance)
                              ).toFixed(2)
                            : "---"}
                        </span>{" "}
                        to{" "}
                        <span className="paragraph-sm">
                          {filters.rt && filters.rt_tollerance
                            ? +(
                                Number(filters.rt) +
                                Number(filters.rt_tollerance)
                              ).toFixed(2)
                            : "---"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <div className={styles.LabelFilter}>Masses</div>
                    <div className="d-flex align-items-center gap-2">
                      <div className={styles.ContainerSwitch}>
                        <div
                          onClick={() =>
                            setFilters({ ...filters, masses: "all" })
                          }
                          className={
                            filters.masses === "all"
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-masses-1"
                        >
                          All masses
                        </div>
                        <div
                          onClick={() =>
                            setFilters({ ...filters, masses: "mz" })
                          }
                          className={
                            filters.masses === "mz"
                              ? styles.ActiveItem
                              : styles.Item
                          }
                          data-test="switch-masses-2"
                        >
                          m/z
                        </div>
                      </div>
                      <input
                        className={styles.InputRT}
                        title="Mass"
                        type={"number"}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            mass_value: e.target.value,
                          })
                        }
                        placeholder="000,000"
                        name="mass_value"
                        value={filters.mass_value}
                        data-test="input-mass1"
                      />
                      <div className={styles.TolleranceContainer}>
                        <input
                          className={classNames(`${styles.InputRtTollerance}`, {
                            "ps-4": filters.mass_tollerance,
                          })}
                          title="Mass tollerance"
                          placeholder={filters.mass_tollerance ? "" : "± 0,000"}
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass_tollerance: e.target.value,
                            })
                          }
                          name="mass_tollerance"
                          value={filters.mass_tollerance}
                          data-test="input-mass2"
                        />
                        {filters.mass_tollerance && (
                          <span className={styles.Unit}>± </span>
                        )}
                      </div>
                    </div>
                    <div className="paragraph-sm-bold opacity-50">
                      Plotted data on y-axis: from{" "}
                      <span className="paragraph-sm">
                        {filters.mass_value && filters.mass_tollerance
                          ? +(
                              Number(filters.mass_value) -
                              Number(filters.mass_tollerance)
                            ).toFixed(2)
                          : "---"}
                      </span>{" "}
                      to{" "}
                      <span className="paragraph-sm">
                        {filters.mass_value && filters.mass_tollerance
                          ? +(
                              Number(filters.mass_value) +
                              Number(filters.mass_tollerance)
                            ).toFixed(2)
                          : "---"}
                      </span>
                    </div>
                  </div>
                  {filters.masses !== "all" && (
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>Related m/z</div>
                      {/* mass 1 */}
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <div className="paragraph-md">Mass 1</div>
                        <input
                          className={styles.InputMass}
                          title="Mass1"
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass1_value: e.target.value,
                            })
                          }
                          placeholder="000,000"
                          name="mass1_value"
                          value={filters.mass1_value}
                          data-test="input-mass1-val"
                        />
                        <div className={styles.TolleranceContainer}>
                          <input
                            className={classNames(`${styles.InputMass}`, {
                              "ps-4": filters.mass1_tollerance,
                            })}
                            title="Mass1 tollerance"
                            placeholder={
                              filters.mass1_tollerance ? "" : "± 0,000"
                            }
                            type={"number"}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                mass1_tollerance: e.target.value,
                              })
                            }
                            name="mass1_tollerance"
                            value={filters.mass1_tollerance}
                            data-test="input-mass1-tol"
                          />
                          {filters.mass1_tollerance && (
                            <span className={styles.Unit}>± </span>
                          )}
                        </div>
                      </div>
                      {/* mass 2 */}
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <div className="paragraph-md">Mass 2</div>
                        <input
                          className={styles.InputMass}
                          title="Mass2"
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass2_value: e.target.value,
                            })
                          }
                          placeholder="000,000"
                          name="mass2_value"
                          value={filters.mass2_value}
                          data-test="input-mass2-val"
                        />
                        <div className={styles.TolleranceContainer}>
                          <input
                            className={classNames(`${styles.InputMass}`, {
                              "ps-4": filters.mass2_tollerance,
                            })}
                            title="Mass2 tollerance"
                            placeholder={
                              filters.mass2_tollerance ? "" : "± 0,000"
                            }
                            type={"number"}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                mass2_tollerance: e.target.value,
                              })
                            }
                            name="mass2_tollerance"
                            value={filters.mass2_tollerance}
                            data-test="input-mass2-tol"
                          />
                          {filters.mass2_tollerance && (
                            <span className={styles.Unit}>± </span>
                          )}
                        </div>
                      </div>
                      {/* mass 3 */}
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <div className="paragraph-md">Mass 3</div>
                        <input
                          className={styles.InputMass}
                          title="Mass3"
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass3_value: e.target.value,
                            })
                          }
                          placeholder="000,000"
                          name="mass3_value"
                          value={filters.mass3_value}
                          data-test="input-mass3-val"
                        />
                        <div className={styles.TolleranceContainer}>
                          <input
                            className={classNames(`${styles.InputMass}`, {
                              "ps-4": filters.mass3_tollerance,
                            })}
                            title="Mass3 tollerance"
                            placeholder={
                              filters.mass3_tollerance ? "" : "± 0,000"
                            }
                            type={"number"}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                mass3_tollerance: e.target.value,
                              })
                            }
                            name="mass3_tollerance"
                            value={filters.mass3_tollerance}
                            data-test="input-mass3-tol"
                          />
                          {filters.mass3_tollerance && (
                            <span className={styles.Unit}>± </span>
                          )}
                        </div>
                      </div>
                      {/* mass 4 */}
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <div className="paragraph-md">Mass 4</div>
                        <input
                          className={classNames(styles.InputMass)}
                          title="Mass4"
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass4_value: e.target.value,
                            })
                          }
                          placeholder="000,000"
                          name="mass4_value"
                          value={filters.mass4_value}
                          data-test="input-mass4-val"
                        />
                        <div className={styles.TolleranceContainer}>
                          <input
                            className={classNames(`${styles.InputMass}`, {
                              "ps-4": filters.mass4_tollerance,
                            })}
                            title="Mass4 tollerance"
                            placeholder={
                              filters.mass4_tollerance ? "" : "± 0,000"
                            }
                            type={"number"}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                mass4_tollerance: e.target.value,
                              })
                            }
                            name="mass4_tollerance"
                            value={filters.mass4_tollerance}
                            data-test="input-mass4-tol"
                          />
                          {filters.mass4_tollerance && (
                            <span className={styles.Unit}>± </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div style={{ flexGrow: 0, flexShrink: 1, flexBasis: 150 }} />

                <div
                  className="d-flex flex-column gap-4"
                  style={{
                    minWidth: 500,
                    marginRight: 100,
                    flexGrow: 0,
                    flexShrink: 1,
                    flexBasis: 456,
                  }}
                >
                  <div className="d-flex flex-column gap-2">
                    <div className={styles.LabelFilter}>Time range</div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        className="d-flex align-items-center gap-2"
                        data-test="datepicker"
                      >
                        <div className="paragraph-md">From</div>
                        <ReactDatePicker
                          className={styles.InputRTDate}
                          onChange={(date) => {
                            const d = date ? format(date, "yyyy-MM-dd") : ""
                            setFilters({
                              ...filters,
                              study__date__gte: String(d) || timespan?.min_date,
                            })
                          }}
                          showYearDropdown
                          dateFormat="MMMM d, yyyy"
                          selected={dateGte}
                        />
                      </div>
                      <div
                        className="d-flex align-items-center gap-2"
                        data-test="datepicker"
                      >
                        <div className="paragraph-md">to</div>
                        <ReactDatePicker
                          className={styles.InputRTDate}
                          onChange={(date) => {
                            const d = date ? format(date, "yyyy-MM-dd") : ""
                            setFilters({
                              ...filters,
                              study__date__lte: String(d) || timespan?.max_date,
                            })
                          }}
                          showYearDropdown
                          dateFormat="MMMM d, yyyy"
                          selected={dateLte}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>Solutions</div>
                      <div
                        className="d-flex align-items-center"
                        data-test="select-items-2"
                      >
                        <Select
                          classNamePrefix={"metadata-filter"}
                          isSearchable={false}
                          isClearable={false}
                          closeMenuOnSelect={false}
                          placeholder={"All solutions"}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 2000 }),
                          }}
                          isMulti
                          value={
                            filters.study__solution__in.length === 0
                              ? solutionsForSelect?.filter(
                                  (d) => d.value === ""
                                )
                              : solutionsForSelect?.filter((d) =>
                                  filters.study__solution__in.includes(d.value)
                                )
                          }
                          components={{
                            MultiValue: LimitedChipsContainer,
                          }}
                          hideSelectedOptions={false}
                          onChange={(values) => {
                            if (
                              filters.study__solution__in.length !== 0 &&
                              values.filter((d) => d.value === "").length > 0
                            ) {
                              setFilters({
                                ...filters,
                                study__solution__in: [],
                              })
                            } else {
                              setFilters({
                                ...filters,
                                study__solution__in: values
                                  .map((c) => c.value)
                                  .filter(Boolean),
                              })
                            }
                          }}
                          options={solutionsForSelect}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>Study</div>
                      <input
                        className={styles.InputStudyKey}
                        title="Study Key"
                        placeholder="Enter code"
                        type={"text"}
                        onChange={(e) =>
                          setFilters({ study__key: e.target.value })
                        }
                        name="study__key"
                        value={filters.study__key}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>Metadata</div>
                      <div
                        className="d-flex align-items-center"
                        data-test="select-metadata"
                      >
                        <Select
                          classNamePrefix={"metadata-filter"}
                          isSearchable={false}
                          value={
                            optionsMetadata.filter(
                              (d) => d.value === filters.metadata
                            )[0]
                          }
                          defaultValue={{
                            value: "operator",
                            label: "Operator",
                          }}
                          onChange={(e) => {
                            setFilters({
                              ...filters,
                              metadata: e ? e.value : "",
                            })
                          }}
                          options={optionsMetadata}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <div className={styles.LabelFilter}>Operators</div>
                      <div
                        className="d-flex align-items-center"
                        data-test="select-items-1"
                      >
                        <Select
                          classNamePrefix={"metadata-filter"}
                          isSearchable={false}
                          isClearable={false}
                          placeholder={"All operators"}
                          closeMenuOnSelect={false}
                          blurInputOnSelect={false}
                          isMulti
                          value={
                            filters.study__operator__in.length === 0
                              ? operatorsForSelect?.filter(
                                  (d) => d.value === ""
                                )
                              : operatorsForSelect?.filter((d) =>
                                  filters.study__operator__in.includes(d.value)
                                )
                          }
                          components={{
                            MultiValue: LimitedChipsContainer,
                          }}
                          hideSelectedOptions={false}
                          onChange={(values) => {
                            if (
                              filters.study__operator__in.length !== 0 &&
                              values.filter((d) => d.value === "").length > 0
                            ) {
                              setFilters({
                                ...filters,
                                study__operator__in: [],
                              })
                            } else {
                              setFilters({
                                ...filters,
                                study__operator__in: values
                                  .map((c) => c.value)
                                  .filter(Boolean),
                              })
                            }
                          }}
                          options={operatorsForSelect}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center gap-4">
                      <div className="d-flex align-items-center gap-2">
                        <div
                          onClick={() => {
                            setFilters({
                              ...filters,
                              has_cas: !filters.has_cas,
                            })
                          }}
                          data-test="checkbox-cas"
                        >
                          {Boolean(filters.has_cas) ? (
                            <CheckIcon />
                          ) : (
                            <UncheckIcon />
                          )}
                        </div>
                        <label className="text-button-md-semibold text-primary">
                          CAS
                        </label>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        <div
                          onClick={() => {
                            setFilters({
                              ...filters,
                              has_note: !filters.has_note,
                            })
                          }}
                          data-test="checkbox-notes"
                        >
                          {Boolean(filters.has_note) ? (
                            <CheckIcon />
                          ) : (
                            <UncheckIcon />
                          )}
                        </div>
                        <label className="text-button-md-semibold text-primary">
                          Notes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingTop: 24,
                  borderBottom: "1px solid rgba(177, 177, 177, 0.20)",
                }}
              />
              <div className="w-100 d-flex justify-content-end py-3">
                <Button
                  className="me-3"
                  variant="outline-secondary"
                  disabled={
                    !enableCompounds &&
                    !isFiltersActive &&
                    !isFiltersActiveApplied &&
                    filters.search === ""
                  }
                  onClick={() => {
                    setFilters(filtersDefault)
                    setQsParams({ ...filtersDefault })
                    setCompoundsEnabled(false)
                  }}
                >
                  Reset
                </Button>
                <Button
                  onClick={() => {
                    setFilters({ page: 1 })
                    if (
                      filters.rt &&
                      filters.rt_tollerance &&
                      filters.mass_value &&
                      filters.mass_tollerance
                    ) {
                      setQsParams({ ...filters, page: 1, view: "chart" })
                    } else {
                      setQsParams({ ...filters, page: 1, view: "table" })
                    }
                    setOpenBlockFilters(false)
                    setCompoundsEnabled(true)
                  }}
                  disabled={
                    !enableCompounds &&
                    !isFiltersActive &&
                    !isFiltersActiveApplied &&
                    filters.search === ""
                  }
                  variant="secondary"
                  className="text-white"
                  data-test="apply"
                >
                  Apply
                </Button>
              </div>
              {/* VECCHI FILTRI, TODO: cancellare dopo test */}
              {/* <div
                style={{ height: 120 }}
                className="d-flex justify-content-between border-bottom border-secondary-opacity pb-5"
              >
                <div>
                  <div className={styles.LabelFilter}>
                    Choose a study cluster
                  </div>
                  <div className={styles.ContainerSwitch}>
                    <div
                      onClick={() =>
                        setFilters({ ...filters, study_cluster: "ESI+" })
                      }
                      className={
                        filters.study_cluster === "ESI+"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-cluster-1"
                    >
                      ESI+
                    </div>
                    <div
                      onClick={() =>
                        setFilters({ ...filters, study_cluster: "ESI-" })
                      }
                      className={
                        filters.study_cluster === "ESI-"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-cluster-2"
                    >
                      ESI-
                    </div>
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className={styles.LabelFilter}>Filter by</div>
                  <div className="d-flex text-nowrap align-items-center mt-3">
                    <div className={styles.RT}>RT</div>
                    <div className="ms-2 d-flex">
                      <input
                        className={styles.InputRT}
                        title="RT"
                        placeholder="000,000"
                        type={"number"}
                        onChange={(e) =>
                          setFilters({ ...filters, rt: e.target.value })
                        }
                        name="rt"
                        value={filters.rt}
                        data-test="input-rt1"
                      />
                      <div className={styles.TolleranceContainer}>
                        <input
                          className={classNames(
                            `${styles.InputRtTollerance} ms-1`,
                            { "ps-4": filters.rt_tollerance }
                          )}
                          title="RT"
                          placeholder={filters.rt_tollerance ? "" : "± 0,000"}
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              rt_tollerance: e.target.value,
                            })
                          }
                          name="rt_tollerance"
                          value={filters.rt_tollerance}
                          data-test="input-rt2"
                        />
                        {filters.rt_tollerance && (
                          <span className={styles.Unit}>± </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div className="mt-3">
                  <div className={styles.LabelFilter}></div>
                  <div className={styles.ContainerSwitch}>
                    <div
                      onClick={() => setFilters({ ...filters, masses: "all" })}
                      className={
                        filters.masses === "all"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-masses-1"
                    >
                      All masses
                    </div>
                    <div
                      onClick={() => setFilters({ ...filters, masses: "mz" })}
                      className={
                        filters.masses === "mz"
                          ? styles.ActiveItem
                          : styles.Item
                      }
                      data-test="switch-masses-2"
                    >
                      m/z
                    </div>
                  </div>
                </div>
                <div className="ms-1 mt-3">
                  <div className="d-flex text-nowrap align-items-center mt-3">
                    <div className="d-flex">
                      <input
                        className={styles.InputRT}
                        title="Mass"
                        type={"number"}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            mass_value: e.target.value,
                          })
                        }
                        placeholder="000,000"
                        name="mass_value"
                        value={filters.mass_value}
                        data-test="input-mass1"
                      />
                      <div className={styles.TolleranceContainer}>
                        <input
                          className={classNames(
                            `${styles.InputRtTollerance} ms-1`,
                            { "ps-4": filters.mass_tollerance }
                          )}
                          title="Mass tollerance"
                          placeholder={filters.mass_tollerance ? "" : "± 0,000"}
                          type={"number"}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              mass_tollerance: e.target.value,
                            })
                          }
                          name="mass_tollerance"
                          value={filters.mass_tollerance}
                          data-test="input-mass2"
                        />
                        {filters.mass_tollerance && (
                          <span className={styles.Unit}>± </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className="d-flex justify-content-between">
                    <div className={styles.LabelFilter}>
                      Choose a time range
                    </div>
                    <div
                      onClick={() => {
                        if (isDateFilterActive) {
                          setFilters({
                            ...filters,
                            study__date__gte: "",
                            study__date__lte: "",
                          })
                        }
                      }}
                      className={
                        isDateFilterActive
                          ? styles.ResetDates
                          : styles.ResetDatesDisabled
                      }
                    >
                      Reset
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3"
                    data-test="datepicker"
                  >
                    <div className={styles.RT}>From</div>
                    <ReactDatePicker
                      className={`${styles.InputRTDate} ms-1`}
                      onChange={(date) => {
                        const d = date ? format(date, "yyyy-MM-dd") : ""
                        setFilters({
                          ...filters,
                          study__date__gte: String(d) || timespan?.min_date,
                        })
                      }}
                      showYearDropdown
                      dateFormat="MMM d, yyyy"
                      selected={dateGte}
                    />
                    <div className={`${styles.RT} ms-1`}>To</div>
                    <ReactDatePicker
                      className={`${styles.InputRTDate} ms-1`}
                      onChange={(date) => {
                        const d = date ? format(date, "yyyy-MM-dd") : ""
                        setFilters({
                          ...filters,
                          study__date__lte: String(d) || timespan?.max_date,
                        })
                      }}
                      showYearDropdown
                      dateFormat="MMM d, yyyy"
                      selected={dateLte}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{ height: 120 }}
                className="d-flex justify-content-between border-bottom border-secondary-opacity pt-4 pb-4"
              >
                <div>
                  <div className={styles.LabelFilter}>
                    Select one metadata to show
                  </div>
                  <div
                    className="mt-3 d-flex align-items-center"
                    data-test="select-metadata"
                  >
                    <Select
                      classNamePrefix={"metadata-filter"}
                      isSearchable={false}
                      value={
                        optionsMetadata.filter(
                          (d) => d.value === filters.metadata
                        )[0]
                      }
                      defaultValue={{ value: "operator", label: "Operator" }}
                      onChange={(e) => {
                        setFilters({ ...filters, metadata: e ? e.value : "" })
                      }}
                      options={optionsMetadata}
                    />
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className={styles.LabelFilter}>
                    Select one or more items
                  </div>
                  <div
                    style={{ height: 38 }}
                    className="mt-3 d-flex align-items-center"
                    data-test="select-items-1"
                  >
                    <Select
                      classNamePrefix={"metadata-filter"}
                      isSearchable={false}
                      isClearable={false}
                      placeholder={"All operators"}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      isMulti
                      value={
                        filters.study__operator__in.length === 0
                          ? operatorsForSelect?.filter((d) => d.value === "")
                          : operatorsForSelect?.filter((d) =>
                              filters.study__operator__in.includes(d.value)
                            )
                      }
                      components={{
                        MultiValue: LimitedChipsContainer,
                      }}
                      hideSelectedOptions={false}
                      onChange={(values) => {
                        if (
                          filters.study__operator__in.length !== 0 &&
                          values.filter((d) => d.value === "").length > 0
                        ) {
                          setFilters({
                            ...filters,
                            study__operator__in: [],
                          })
                        } else {
                          setFilters({
                            ...filters,
                            study__operator__in: values
                              .map((c) => c.value)
                              .filter(Boolean),
                          })
                        }
                      }}
                      options={operatorsForSelect}
                    />
                    <div className="me-3 ms-3 w-1-secondary"></div>
                    <div
                      className="d-flex align-items-center"
                      data-test="select-items-2"
                    >
                      <Select
                        classNamePrefix={"metadata-filter"}
                        isSearchable={false}
                        isClearable={false}
                        closeMenuOnSelect={false}
                        placeholder={"All solutions"}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 100 }),
                        }}
                        isMulti
                        value={
                          filters.study__solution__in.length === 0
                            ? solutionsForSelect?.filter((d) => d.value === "")
                            : solutionsForSelect?.filter((d) =>
                                filters.study__solution__in.includes(d.value)
                              )
                        }
                        components={{
                          MultiValue: LimitedChipsContainer,
                        }}
                        hideSelectedOptions={false}
                        onChange={(values) => {
                          if (
                            filters.study__solution__in.length !== 0 &&
                            values.filter((d) => d.value === "").length > 0
                          ) {
                            setFilters({
                              ...filters,
                              study__solution__in: [],
                            })
                          } else {
                            setFilters({
                              ...filters,
                              study__solution__in: values
                                .map((c) => c.value)
                                .filter(Boolean),
                            })
                          }
                        }}
                        options={solutionsForSelect}
                      />
                    </div>
                  </div>
                </div>
                <div className="me-2 ms-2 w-1-secondary"></div>
                <div>
                  <div className="mt-4 pt-2 d-flex align-items-center">
                    <div className={`${styles.RT} me-2`}>Study</div>
                    <input
                      className={styles.InputStudyKey}
                      title="Study Key"
                      placeholder="Enter code"
                      type={"text"}
                      onChange={(e) =>
                        setFilters({ study__key: e.target.value })
                      }
                      name="study__key"
                      value={filters.study__key}
                    />
                  </div>
                </div>
                <div className="me-4 ms-4 w-1-secondary"></div>
                <div>
                  <div className={styles.LabelFilter}>
                    Show only results with:
                  </div>
                  <div className="mt-4 d-flex align-items-center">
                    <div
                      onClick={() => {
                        setFilters({
                          ...filters,
                          has_cas: !filters.has_cas,
                        })
                      }}
                      className="checkbox-container"
                      data-test="checkbox-cas"
                    >
                      {Boolean(filters.has_cas) ? <CheckIcon /> : ""}
                    </div>
                    <label className={`${styles.RT} ms-2`}>CAS</label>
                    <div
                      onClick={() => {
                        setFilters({
                          ...filters,
                          has_note: !filters.has_note,
                        })
                      }}
                      className="checkbox-container ms-3"
                      data-test="checkbox-notes"
                    >
                      {Boolean(filters.has_note) ? <CheckIcon /> : ""}
                    </div>
                    <label className={`${styles.RT} ms-2`}>Notes</label>
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-end pt-3">
                <Button
                  className="me-3"
                  variant="outline-secondary"
                  disabled={
                    !enableCompounds &&
                    !isFiltersActive &&
                    !isFiltersActiveApplied &&
                    filters.search === ""
                  }
                  onClick={() => {
                    setFilters(filtersDefault)
                    setQsParams({ ...filtersDefault })
                    setCompoundsEnabled(false)
                  }}
                >
                  Reset
                </Button>
                <Button
                  onClick={() => {
                    setFilters({ page: 1 })
                    setQsParams({ ...filters, page: 1 })
                    setOpenBlockFilters(false)
                    setCompoundsEnabled(true)
                  }}
                  disabled={
                    !enableCompounds &&
                    !isFiltersActive &&
                    !isFiltersActiveApplied &&
                    filters.search === ""
                  }
                  variant="secondary"
                  className="text-white"
                  data-test="apply"
                >
                  Apply
                </Button>
              </div> */}
            </div>
          </div>
        </div>

        <div className={styles.Menu}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className={
                  qsParams.view === "chart"
                    ? styles.MenuItemActive
                    : styles.MenuItem
                }
                onClick={() => setQsParams({ ...qsParams, view: "chart" })}
              >
                Chart
              </div>
              <div
                className={
                  qsParams.view === "table"
                    ? styles.MenuItemActive
                    : styles.MenuItem
                }
                onClick={() => setQsParams({ ...qsParams, view: "table" })}
              >
                Table
              </div>
            </div>
          </div>
          {/* Filter Chips */}
          <div className="d-flex align-items-center gap-2">
            <div className="paragraph-sm">Applied filters</div>
            <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
              <div className="paragraph-sm-bold">{`${filters.study_cluster}`}</div>
            </div>
            {filters.rt && filters.rt_tollerance && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">RT</div>
                <div className="paragraph-sm">{`${filters.rt} ± ${filters.rt_tollerance}`}</div>
              </div>
            )}
            {filters.mass_value && filters.mass_tollerance && filters.masses === "all" && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">All masses</div>
                <div className="paragraph-sm">{`${filters.mass_value} ± ${filters.mass_tollerance}`}</div>
              </div>
            )}
            {filters.mass_value && filters.mass_tollerance && filters.masses === "mz" && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">m/z</div>
                <div className="paragraph-sm">{`${filters.mass_value} ± ${filters.mass_tollerance}`}</div>
              </div>
            )}
            {filters.study__operator__in.toString() && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Operators</div>
                <div className="paragraph-sm">{`${filters.study__operator__in}`}</div>
              </div>
            )}
            {filters.study__solution__in.toString() && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Solution</div>
                <div className="paragraph-sm">{`${filters.study__solution__in}`}</div>
              </div>
            )}
            {filters.study__key && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Study</div>
                <div className="paragraph-sm">{`${filters.study__key}`}</div>
              </div>
            )}
            {filters.has_cas && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Cas</div>
              </div>
            )}
            {filters.has_note && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Note</div>
              </div>
            )}
            {filters.mass1_value && filters.mass1_tollerance && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Mass 1</div>
                <div className="paragraph-sm">{`${filters.mass1_value} ± ${filters.mass1_tollerance}`}</div>
              </div>
            )}
            {filters.mass2_value && filters.mass2_tollerance && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Mass 2</div>
                <div className="paragraph-sm">{`${filters.mass2_value} ± ${filters.mass2_tollerance}`}</div>
              </div>
            )}
            {filters.mass3_value && filters.mass3_tollerance && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Mass 3</div>
                <div className="paragraph-sm">{`${filters.mass3_value} ± ${filters.mass3_tollerance}`}</div>
              </div>
            )}
            {filters.mass4_value && filters.mass4_tollerance && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">Mass 4</div>
                <div className="paragraph-sm">{`${filters.mass4_value} ± ${filters.mass4_tollerance}`}</div>
              </div>
            )}
            {filters.study__date__gte && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">From</div>
                <div className="paragraph-sm">{`${dayjs(
                  filters.study__date__gte
                ).format("MMM D, YYYY")}`}</div>
              </div>
            )}
            {filters.study__date__lte && (
              <div className="d-flex align-items-center gap-2 rounded-pill bg-light-grey px-2 py-1">
                <div className="paragraph-sm-bold">to</div>
                <div className="paragraph-sm">{`${dayjs(
                  filters.study__date__lte
                ).format("MMM D, YYYY")}`}</div>
              </div>
            )}
          </div>
        </div>

        {qsParams.view === "chart" && (
          <ChartPage
            filters={{
              ...filters,
              view: filters.view as "chart" | "table",
              feed_cat: filters.feed_cat as FeedbackKind,
              feed_order: filters.feed_order as "" | "asc" | "desc",
            }}
            filtersQuery={apiFilters}
            setFiltersQuery={setQsParams}
            blockFilters={blockFilters}
            setOpenBlockFilters={setOpenBlockFilters}
          />
        )}

        {qsParams.view === "table" && (
          <TablePage
            data={data}
            enableCompounds={enableCompounds}
            filters={{
              ...filters,
              view: "table",
              feed_cat: filters.feed_cat as FeedbackKind,
              feed_order: filters.feed_order as "" | "asc" | "desc",
            }}
            setFilters={setFilters}
            qsParams={{
              ...qsParams,
              view: "table",
              feed_cat: qsParams.feed_cat as FeedbackKind,
              feed_order: qsParams.feed_order as "" | "asc" | "desc",
            }}
            setQsParams={setQsParams}
          />
        )}
      </div>
    </div>
  )
}
