import * as d3 from "d3"
import { useState } from "react"
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from "react-zoom-pan-pinch"
import { ReactComponent as Add } from "../../../../assets/icons/add.svg"
import { ReactComponent as Aiming } from "../../../../assets/icons/aiming.svg"
import { ReactComponent as Minus } from "../../../../assets/icons/minus.svg"
import { ChartPoints } from "../../../../types"
import { QuadrantsType, TargetRangeType } from "../../type"
import styles from "../ChartPage.module.css"
import { Axes } from "./Axes"
import { LabelsGrid } from "./Labels"
import { QuadrantGrid } from "./QuadrantGrid"
import { TooltipWrapper } from "./Tooltip"

type ScatterChartProps = {
  data: ChartPoints[]
  rt: { value: number; tollerance: number }
  mz: { value: number; tollerance: number }
  quadrants: QuadrantsType
  targetRange: TargetRangeType
}

export function ScatterChart({
  data,
  rt,
  mz,
  quadrants,
  targetRange,
}: ScatterChartProps) {
  const [dotTooltip, setDotTooltip] = useState<[ChartPoints, DOMRect] | null>()
  const size = 600
  const margin = 65
  const sizeChart = size - margin * 2
  const makePPM = (result: number) => ((result - mz.value) / mz.value) * 1e6

  let yOuterBottom = makePPM(mz.value - mz.tollerance)
  let yOuterTop = makePPM(mz.value + mz.tollerance)
  let xOuterEnd = rt.value + rt.tollerance
  let xOuterStart = rt.value - rt.tollerance
  let gridSteps = [1 / 3, 2 / 3, 1]
  let labelSteps = [0, 1 / 3, 2 / 3, 1]

  switch (targetRange) {
    case "All":
      break
    case "Mid":
      yOuterBottom = makePPM(mz.value - (mz.tollerance / 3) * 2)
      yOuterTop = makePPM(mz.value + (mz.tollerance / 3) * 2)
      xOuterEnd = rt.value + (rt.tollerance / 3) * 2
      xOuterStart = rt.value - (rt.tollerance / 3) * 2
      gridSteps = [1 / 2, 1]
      labelSteps = [0, 1 / 2, 1]
      break
    case "Closest":
      yOuterBottom = makePPM(mz.value - mz.tollerance / 3)
      yOuterTop = makePPM(mz.value + mz.tollerance / 3)
      xOuterEnd = rt.value + rt.tollerance / 3
      xOuterStart = rt.value - rt.tollerance / 3
      gridSteps = [1]
      labelSteps = [0, 1]
      break
  }

  switch (quadrants) {
    case "All":
      labelSteps = [1]
      break
    case "First":
      yOuterBottom = makePPM(mz.value)
      xOuterStart = rt.value
      break
    case "Second":
      yOuterBottom = makePPM(mz.value)
      xOuterEnd = rt.value
      break
    case "Third":
      yOuterTop = makePPM(mz.value)
      xOuterEnd = rt.value
      break
    case "Fourth":
      yOuterTop = makePPM(mz.value)
      xOuterStart = rt.value
      break
  }

  const xScale = d3
    .scaleLinear()
    .domain([xOuterStart, xOuterEnd])
    .range([margin, size - margin])

  const yScale = d3
    .scaleLinear()
    .domain([yOuterBottom, yOuterTop])
    .range([size - margin, margin])

  return (
    <TransformWrapper>
      <div className={styles.ChartSection}>
        <TransformComponent wrapperClass="w-100 h-100">
          <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
            <div
              id="scatterChart"
              style={{ width: "100%", height: "100%", position: "relative" }}
            >
              <svg width={size} height={size}>
                <QuadrantGrid
                  quadrant={quadrants}
                  x={xScale(rt.value)}
                  y={yScale(makePPM(mz.value))}
                  size={sizeChart}
                  steps={gridSteps}
                />
                <LabelsGrid
                  quadrant={quadrants}
                  x={xScale(rt.value)}
                  y={yScale(makePPM(mz.value))}
                  size={sizeChart}
                  steps={labelSteps}
                  domain={{
                    xOuterStart,
                    xOuterEnd,
                    yOuterTop,
                    yOuterBottom,
                  }}
                />
                <Axes
                  quadrant={quadrants}
                  x={xScale(rt.value)}
                  y={yScale(makePPM(mz.value))}
                  size={sizeChart}
                />

                {/* DOT */}
                {data
                  ?.filter((item) => {
                    const ppm = makePPM(item.mz)
                    return (
                      ppm >= yOuterBottom &&
                      ppm <= yOuterTop &&
                      item.rt >= xOuterStart &&
                      item.rt <= xOuterEnd
                    )
                  })
                  ?.map((item) => {
                    const ppm = makePPM(item.mz)
                    return (
                      <circle
                        key={item.id}
                        cx={xScale(item.rt)}
                        cy={yScale(ppm)}
                        fill={
                          !dotTooltip
                            ? "var(--primary)"
                            : dotTooltip[0].id !== item.id
                            ? "#cedaea"
                            : "var(--primary)"
                        }
                        r="6.5"
                        stroke="#FFF"
                        strokeWidth={1}
                        onMouseEnter={(e) =>
                          setDotTooltip([
                            item,
                            (
                              e.target as SVGCircleElement
                            ).getBoundingClientRect(),
                          ])
                        }
                        onMouseLeave={() => setDotTooltip(null)}
                      />
                    )
                  })}
              </svg>
            </div>
          </div>
        </TransformComponent>
        {dotTooltip && (
          <TooltipWrapper
            datum={dotTooltip[0]}
            bbox={dotTooltip[1]}
            xScale={xScale}
            yScale={yScale}
            size={size}
          />
        )}
      </div>
      <Controls />
    </TransformWrapper>
  )
}

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls()
  const [zoom, setZoom] = useState<"in" | "out" | "center" | null>()

  return (
    <div className="tools">
      <div style={{ position: "absolute", bottom: 12, right: 50 }}>
        <div className="d-flex align-items-center gap-3 justify-content-end">
          {zoom === "in" && (
            <div className="paragraph-sm bg-white rounded p-1">Zoom-in</div>
          )}
          <div
            className={styles.ZoomIn}
            onMouseEnter={() => setZoom("in")}
            onMouseLeave={() => setZoom(null)}
            onClick={() => zoomIn()}
          >
            <Add />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 justify-content-end">
          {zoom === "out" && (
            <div className="paragraph-sm bg-white rounded p-1">Zoom-out</div>
          )}
          <div
            className={styles.ZoomOut}
            onMouseEnter={() => setZoom("out")}
            onMouseLeave={() => setZoom(null)}
            onClick={() => zoomOut()}
          >
            <Minus />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 justify-content-end">
          {zoom === "center" && (
            <div className="paragraph-sm bg-white rounded p-1">Reset zoom</div>
          )}
          <div
            className={styles.ZoomAiming}
            onMouseEnter={() => setZoom("center")}
            onMouseLeave={() => setZoom(null)}
            onClick={() => resetTransform()}
          >
            <Aiming />
          </div>
        </div>
      </div>
    </div>
  )
}
