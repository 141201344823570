import { keepPreviousData, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useAuthCallPromise } from "use-eazy-auth"
import { UserStatisticsType } from "../types"

export function useUserStatistics(params: Record<string, any> = {}) {
  return useQuery<UserStatisticsType>({
    queryKey: ["user-statistics", params],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/v1/stats/?${new URLSearchParams(params).toString()}`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData,
  })
}
