import { QuadrantsType } from "../../type"

type QuadrantProps = {
  quadrant: QuadrantsType
  x: number
  y: number
  size: number
}

export const Axes = ({ quadrant, x, y, size }: QuadrantProps) => {
  let xAxis, yAxis
  let arrow = { rotate: 0, x, y, offset: size }
  let extra = 6
  switch (quadrant) {
    case "First":
      xAxis = <Axis x1={x} x2={x + size + extra} y1={y} y2={y} />
      yAxis = <Axis x1={x} x2={x} y1={y - size - extra} y2={y} />
      arrow = { ...arrow, rotate: 0 }
      break
    case "Second":
      xAxis = <Axis x1={x - size - extra} x2={x} y1={y} y2={y} />
      yAxis = <Axis x1={x} x2={x} y1={y - size - extra} y2={y} />
      arrow = { ...arrow, rotate: 270 }
      break
    case "Third":
      xAxis = <Axis x1={x - size - extra} x2={x} y1={y} y2={y} />
      yAxis = <Axis x1={x} x2={x} y1={y} y2={y + size + extra} />
      arrow = { ...arrow, rotate: 180 }
      break
    case "Fourth":
      xAxis = <Axis x1={x} x2={x + size + extra} y1={y} y2={y} />
      yAxis = <Axis x1={x} x2={x} y1={y} y2={y + size + extra} />
      arrow = { ...arrow, rotate: 90 }
      break
    case "All":
      xAxis = (
        <>
          <line
            x1={x - size / 2 - 7}
            x2={x + size / 2 + 7}
            y1={y}
            y2={y}
            stroke="var(--primary)"
            strokeWidth="1"
            strokeLinecap="round"
            opacity={0.6}
          />
        </>
      )
      yAxis = (
        <>
          <line
            y1={y - size / 2 - 7}
            y2={y + size / 2 + 7}
            x1={x}
            x2={x}
            stroke="var(--primary)"
            strokeWidth="1"
            strokeLinecap="round"
            opacity={0.6}
          />
        </>
      )
      arrow = { ...arrow, offset: size / 2 }
  }
  return (
    <>
      {xAxis}
      {yAxis}
      <g transform={`rotate (${arrow.rotate}, ${arrow.x}, ${arrow.y})`}>
        <line
          y1={y - arrow.offset - 4}
          y2={y - arrow.offset - 7}
          x1={x + 3}
          x2={x}
          stroke="var(--primary)"
          strokeWidth="1"
          strokeLinecap="round"
          opacity={0.6}
        />
        <line
          y1={y - arrow.offset - 4}
          y2={y - arrow.offset - 7}
          x1={x - 3}
          x2={x}
          stroke="var(--primary)"
          strokeWidth="1"
          strokeLinecap="round"
          opacity={0.6}
        />
        <line
          x1={x + arrow.offset + 4}
          x2={x + arrow.offset + 7}
          y1={y + 3}
          y2={y}
          stroke="var(--primary)"
          strokeWidth="1"
          strokeLinecap="round"
          opacity={0.6}
        />
        <line
          x1={x + arrow.offset + 4}
          x2={x + arrow.offset + 7}
          y1={y - 3}
          y2={y}
          stroke="var(--primary)"
          strokeWidth="1"
          strokeLinecap="round"
          opacity={0.6}
        />
      </g>
    </>
  )
}

type AxisProps = {
  x1: number
  x2: number
  y1: number
  y2: number
}
const Axis = ({ x1, x2, y1, y2 }: AxisProps) => {
  return (
    <line
      x1={x1}
      x2={x2}
      y1={y1}
      y2={y2}
      stroke="var(--primary)"
      fill="var(--primary)"
      strokeWidth="1"
      opacity={0.6}
    />
  )
}
