import classNames from "classnames"
import { Button, Modal } from "react-bootstrap"
import { ReactComponent as Close } from "../../../assets/icons/close-upload.svg"
import { ReactComponent as SafeAlert } from "../../../assets/icons/safe-alert.svg"
import { ReactComponent as TimeDuration } from "../../../assets/icons/time-duration.svg"
import { ReactComponent as Unlink } from "../../../assets/icons/unlink.svg"
import { Compound, Feedback, FeedbackKind } from "../../../types"
import styles from "./TablePage.module.css"

interface ConfirmModalProps {
  show: boolean
  toggle: () => void | undefined
  onConfirm: () => void
  compound: Compound
  lastFeedback?: Feedback
  comment?: string
  onClosed: () => void
}

export default function ConfirmModal({
  show,
  toggle,
  compound,
  lastFeedback,
  comment,
  onConfirm,
  onClosed,
}: ConfirmModalProps) {
  return (
    <Modal centered show={show} onHide={toggle} data-test="modal-confirm">
      <Modal.Body
        className={"ModalBody pt-2"}
        style={{
          paddingTop: 34,
          paddingBottom: 34,
          paddingLeft: 87,
          paddingRight: 87,
        }}
      >
        <div
          className="d-flex justify-content-end pointer"
          onClick={toggle}
          style={{ marginRight: -80 }}
        >
          <Close
            className="text-primary"
            style={{ width: 24, height: 24, margin: 4 }}
          />
        </div>
        <h3 className="title-h3 text-primary text-center">Are you sure?</h3>
        <div className="paragraph-md mt-3 mb-4 text-center">
          {"You will delete the "}
          <span className="paragraph-md-bold">previous comment</span>
          {` for ${compound.identification}. By deleting it, it will no longer be readable by the community.`}
        </div>

        {lastFeedback && (
          <div className="mb-2" style={{ overflow: "auto" }}>
            <div className={styles.FeedbackBox}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="paragraph-md-bold text-secondary">You</div>
                <FeedbackChip kind={lastFeedback.kind} />
              </div>
              {lastFeedback.comment && (
                <div className={styles.FeedbackComment}>
                  {lastFeedback.comment}
                </div>
              )}
            </div>
          </div>
        )}

        {comment && (
          <>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(177, 177, 177, 0.2)",
                paddingBottom: 8,
                paddingTop: 8,
                paddingRight: 12,
                paddingLeft: 12,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              }}
            >
              <div className="paragraph-md-bold text-primary">You</div>
              <div
                className={classNames(
                  styles.FeedbackChipBlueFilled,
                  "bg-primary text-white paragraph-sm"
                )}
                style={{ cursor: "default" }}
              >
                New
              </div>
            </div>
            <div className="pb-2">
              <textarea
                placeholder="Type here.. (max. 100 characters)"
                value={comment}
                disabled
                className={styles.TextAreaField}
                rows={2}
                maxLength={100}
              />
              <div
                className="d-flex justify-content-end"
                style={{
                  backgroundColor: "rgba(177, 177, 177, 0.2)",
                  paddingBottom: 8,
                  paddingTop: 8,
                  paddingRight: 12,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                }}
              />
            </div>
          </>
        )}
        <div className="d-flex align-items-center justify-content-between mt-4">
          <Button variant="outline-secondary" onClick={() => onClosed()}>
            Cancel
          </Button>
          <Button variant="secondary text-white" onClick={() => onConfirm()}>
            Confirm
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const FeedbackChip = ({ kind }: { kind?: FeedbackKind }) => {
  return (
    <>
      {kind === "mass_mismatch" && (
        <div
          className={styles.FeedbackChipRedFilled}
          style={{ cursor: "default" }}
        >
          <Unlink />
          <div className="paragraph-sm">Mass mismatch</div>
        </div>
      )}
      {kind === "ambiguous_data" && (
        <div className={styles.FeedbackChipYellowFilled}>
          <SafeAlert />
          <div className="paragraph-sm">Ambigous data</div>
        </div>
      )}
      {kind === "uncertain_time" && (
        <div className={styles.FeedbackChipYellowFilled}>
          <TimeDuration />
          <div className="paragraph-sm">Uncertain time</div>
        </div>
      )}
    </>
  )
}
