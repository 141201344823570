import { useState } from "react"
import { Navigate } from "react-router-dom"
import { useAuthUser } from "use-eazy-auth"
import { useQsFilters } from "../../hooks/filters"
import { useUserStatistics } from "../../hooks/userStatistics"
import { AccountUser } from "../../types"
import styles from "./UserStatistics.module.css"
import AvgQueriesChart from "./components/AvgQueriesChart"
import { Banner } from "./components/Banner"
import { Filters } from "./components/Filters"
import HitRatioChart from "./components/HitRatioChart"

const initFilters = (params: URLSearchParams) => ({
  from_date: params.get("from_date") ?? "",
  to_date: params.get("to_date") ?? "",
})

export function UserStatistics() {
  const { filters, setFilters } = useQsFilters(initFilters)
  const { data } = useUserStatistics(filters)
  const [showAverage, setShowAverage] = useState(true)
  const [showHitRatio, setShowHitRatio] = useState(true)
  const { user } = useAuthUser<AccountUser>()

  if (!user?.permission_accounts) return <Navigate to={"/"} />

  return (
    <div className="w-100 h-100 mb-3">
      <div className={styles.userStatistics}>
        <Banner data={data} />
        <div className={styles.content}>
          <Filters
            data={data}
            filters={filters}
            setFilters={setFilters}
            showAverage={showAverage}
            setShowAverage={setShowAverage}
            showHitRatio={showHitRatio}
            setShowHitRatio={setShowHitRatio}
          />
          <div className={styles.divider} />
          <div className={styles.chartContent}>
            {showAverage && !showHitRatio && (
              <>
                <div className="text-button-md-semibold text-black-15 pb-1">
                  Average queries over time
                </div>
                <AvgQueriesChart data={data} />
              </>
            )}
            {showAverage && showHitRatio && (
              <>
                <div className="text-button-md-semibold text-black-15 pb-1">
                  Average queries over time
                </div>
                <AvgQueriesChart data={data} />
                <div style={{ paddingBottom: 48 }} />
                <div className="text-button-md-semibold text-black-15 pb-1">
                  Hit ratio over time
                </div>
                <HitRatioChart data={data} />
              </>
            )}
            {!showAverage && showHitRatio && (
              <>
                <div className="text-button-md-semibold text-black-15 pb-1">
                  Hit ratio over time
                </div>
                <HitRatioChart data={data} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserStatistics
