import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import axios from "axios"
import { useAuthCallPromise } from "use-eazy-auth"
import { Feedback, FeedbackPayload } from "../types"

export function useSetFeedback() {
  const client = useQueryClient()
  return useMutation<
    Feedback,
    Error,
    { id: number; feedback: FeedbackPayload }
  >({
    mutationFn: useAuthCallPromise(
      (token: string) =>
        ({ id, feedback }: { id: number; feedback: FeedbackPayload }) =>
          axios
            .post(`/api/v1/compound/${id}/set-feedback/`, feedback, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data as Feedback)
    ),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["compounds"],
      })
      client.invalidateQueries({
        queryKey: ["feedbacks"],
      })
    },
  })
}

export function useFeedbacks(id: string) {
  return useQuery<Feedback[]>({
    queryKey: ["feedbacks", id],
    queryFn: useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/v1/compound/${id}/feedbacks/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    ),
    placeholderData: keepPreviousData,
  })
}

export function useRemoveFeedback() {
  const client = useQueryClient()
  return useMutation<Feedback, Error, { id: number }>({
    mutationFn: useAuthCallPromise(
      (token: string) =>
        ({ id }: { id: number }) =>
          axios
            .post(
              `/api/v1/compound/${id}/remove-feedback/`,
              {},
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data as Feedback)
    ),
    onSuccess() {
      client.invalidateQueries({
        queryKey: ["compounds"],
      })
      client.invalidateQueries({
        queryKey: ["feedbacks"],
      })
    },
  })
}
