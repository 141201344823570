import { ScaleLinear } from "d3-scale"
import { CSSProperties } from "react"
import { ReactComponent as LegendX } from "../../../../assets/icons/legend-x.svg"
import { ReactComponent as LegendY } from "../../../../assets/icons/legend-y.svg"
import { ChartPoints } from "../../../../types"
import styles from "../ChartPage.module.css"

type TooltipWrapperProps = {
  datum: ChartPoints
  bbox: DOMRect
  xScale: ScaleLinear<number, number>
  yScale: ScaleLinear<number, number>
  size: number
}

export function TooltipWrapper({
  datum,
  bbox,
  xScale,
  yScale,
  size,
}: TooltipWrapperProps) {
  const tooltipWidth = 227
  const x =
    window.innerWidth - bbox.right > 300
      ? bbox.right + 12
      : bbox.left - tooltipWidth - 11
  const y = bbox.top + bbox.height / 2 - 23

  const tooltipStyle: CSSProperties = {
    top: y,
    left: x,
    position: "absolute",
    zIndex: 100,
    transform:
      bbox.top > window.innerHeight / 2
        ? "translateY(calc(-100% + 46px))"
        : undefined,
  }

  const boxStyle: CSSProperties = {
    top:
      bbox.top > window.innerHeight / 2
        ? bbox.top + bbox.height / 2 - 7
        : bbox.top + bbox.height / 2 - 8,
    left:
      window.innerWidth - bbox.right > 300
        ? bbox.left + bbox.width + 5
        : bbox.left - 14 - 5,
    position: "absolute",
    zIndex: 101,
    transform:
      window.innerWidth - bbox.right > 300 ? "rotate(45deg)" : "rotate(225deg)",
  }
  return (
    <>
      <div style={tooltipStyle}>
        <Tooltip datum={datum} />
      </div>
      <div style={boxStyle}>
        <div className={styles.TooltipPop}></div>
      </div>
    </>
  )
}

const Tooltip = ({ datum }: { datum: ChartPoints }) => {
  return (
    <div className={styles.Tooltip}>
      <div className="paragraph-sm-semibold">{datum.identification}</div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center gap-2">
          <LegendX />
          <div className="paragraph-sm">Δ m/z</div>
        </div>
        <div className="paragraph-sm-semibold">{datum.mz.toFixed(2)}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex align-items-center gap-2">
          <LegendY />
          <div className="paragraph-sm">Δ RT</div>
        </div>
        <div className="paragraph-sm-semibold">{datum.rt.toFixed(1)}</div>
      </div>
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "#F4F4F4",
          marginLeft: -16,
          marginRight: -16,
        }}
      />
      {datum.identification && (
        <div>
          <div className={styles.TooltipLabel}>Name</div>
          <div className={styles.TooltipText}>{datum.identification}</div>
        </div>
      )}
      {datum.cas && (
        <div>
          <div className={styles.TooltipLabel}>CAS</div>
          <div className={styles.TooltipText}>{datum.cas}</div>
        </div>
      )}
      {datum.note && (
        <div>
          <div className={styles.TooltipLabel}>Note</div>
          <div className={styles.TooltipText}>{datum.note}</div>
        </div>
      )}
    </div>
  )
}
