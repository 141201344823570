import dayjs from "dayjs"
import { useState } from "react"
import {
  CartesianGrid,
  Customized,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { UserStatisticsType } from "../../../types"
import styles from "../UserStatistics.module.css"

type AvgQueriesChartProps = {
  data?: UserStatisticsType
}

export default function AvgQueriesChart({ data }: AvgQueriesChartProps) {
  const [{ width: viewportWidth, height: viewportHeight }, setViewportSize] =
    useState({ width: 0, height: 0 })
  const n = data?.period?.length || 1
  const dynamicPadding = viewportWidth / n / 2
  const maxAverage = Math.max(
    ...(data?.period?.map((item) => item.queries_over_time) ?? [])
  )
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      onResize={(width, height) => setViewportSize({ width, height })}
    >
      <LineChart
        width={viewportWidth}
        height={viewportHeight}
        data={data?.period}
        margin={{ top: 12, bottom: -5 }}
      >
        <Customized component={CustomizeGrid} />
        <CartesianGrid
          vertical={false}
          horizontal={true}
          stroke="#151515"
          opacity={0.2}
          strokeDasharray="2 2"
        />

        <YAxis
          axisLine={false}
          tickLine={false}
          orientation="right"
          tickMargin={10}
          tick={CustomYAxisTick}
        />
        <Line
          dataKey="queries_over_time"
          stroke="var(--primary)"
          dot={{ fill: "var(--primary)", r: 6 }}
        />
        <Tooltip content={<CustomTooltip maxAverage={maxAverage} />} cursor={false} />
        <XAxis
          tick={CustomXAxisTick}
          dataKey="month"
          stroke="#151515"
          axisLine={{ stroke: "#c0c0c0", height: 3 }}
          tickLine={false}
          interval={0}
          padding={{ left: dynamicPadding, right: dynamicPadding }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

const CustomizeGrid = (props: any) => {
  return (
    <>
      <line
        x1={0}
        x2={0}
        y1={props.margin.top}
        y2={props.offset.height + props.margin.top}
        stroke="#F4F4F4"
        strokeWidth={2}
      />
      {props?.data?.map((_: any, i: number, list: UserStatisticsType[]) => {
        return (
          <line
            x1={(props.offset.width / list.length) * (i + 1)}
            x2={(props.offset.width / list.length) * (i + 1)}
            y1={props.margin.top}
            y2={props.offset.height + props.margin.top}
            stroke="#F4F4F4"
            strokeWidth={1}
          />
        )
      })}
    </>
  )
}
const CustomXAxisTick = ({ x, y, payload }: any) => {
  return (
    <foreignObject width={60} height={15} x={x - 60 / 2} y={y + 2}>
      <div
        className="paragraph-sm text-black-15 text-center"
        style={{ opacity: 0.6 }}
      >
        {dayjs(payload.value).format("MMM, YYYY")}
      </div>
    </foreignObject>
  )
}
const CustomYAxisTick = ({ x, y, payload }: any) => {
  return (
    <foreignObject width={70} height={38} x={x} y={y - 8}>
      <div
        className="paragraph-sm-bold text-black-15 text-start"
        style={{ opacity: 0.6, fontWeight: 700 }}
      >
        {payload.value}
      </div>
    </foreignObject>
  )
}

const CustomTooltip = ({ active, payload, coordinate, maxAverage, ...props }: any) => {
  if (active && payload && payload.length) {
    const { x } = coordinate || {}
    const y =
      props.viewBox.height -
      ((payload[0].value - 0) / (maxAverage - 0)) * props.viewBox.height +
      props.offset +
      13
    return (
      <div
        className={styles.tooltipHitRatio}
        style={{
          position: "absolute",
          left: x - 17.5,
          top: y + 10,
        }}
      >
        <div className="paragraph-sm-bold text-black-15">
          {`${payload[0].value.toFixed(0)}`}
        </div>
      </div>
    )
  }

  return null
}
